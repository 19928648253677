$icons-font-family: "cbicons" !default;
@font-face {
  font-family: '#{$icons-font-family}';
  src:  url('#{$assets-path}/assets/fonts/cbicons/v1/cbicons.eot');
  src:
    url('#{$assets-path}/assets/fonts/cbicons/v1/cbicons.eot#iefix') format('embedded-opentype'),
    url('#{$assets-path}/assets/fonts/cbicons/v1/cbicons.woff2') format('woff2'),
    url('#{$assets-path}/assets/fonts/cbicons/v1/cbicons.ttf') format('truetype'),
    url('#{$assets-path}/assets/fonts/cbicons/v1/cbicons.woff') format('woff'),
    url('#{$assets-path}/assets/fonts/cbicons/v1/cbicons.svg#cbicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="cb-icon-"], [class*=" cb-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icons-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

$cbicon-esports-controller-U0xE945: "\e945";
$cbicon-notify-U0xE900: "\e900";
$cbicon-nba-amend_nba-U0xE943: "\e943";
$cbicon-pools-menu-U0xE942: "\e942";
$cbicon-pluscircle-U0xE940: "\e940";
$cbicon-trash-U0xE941: "\e941";
$cbicon-join-U0xE93e: "\e93e";
$cbicon-clockalert-U0xE93f: "\e93f";
$cbicon-arrow-back-U0xE901: "\e901";
$cbicon-arrow-forward-U0xE902: "\e902";
$cbicon-call-U0xE903: "\e903";
$cbicon-card-U0xE904: "\e904";
$cbicon-cash-out-U0xE905: "\e905";
$cbicon-cbadge-U0xE906: "\e906";
$cbicon-chat-U0xE907: "\e907";
$cbicon-check-U0xE908: "\e908";
$cbicon-checkbox-U0xE909: "\e909";
$cbicon-chevron-back-U0xE90a: "\e90a";
$cbicon-chevron-down-U0xE90b: "\e90b";
$cbicon-chevron-right-U0xE90c: "\e90c";
$cbicon-chevron-up-U0xE90d: "\e90d";
$cbicon-close-U0xE90e: "\e90e";
$cbicon-completed-U0xE90f: "\e90f";
$cbicon-contributors-U0xE910: "\e910";
$cbicon-darts-U0xE911: "\e911";
$cbicon-deposit-U0xE912: "\e912";
$cbicon-dogs-U0xE913: "\e913";
$cbicon-external-U0xE914: "\e914";
$cbicon-facebook-U0xE915: "\e915";
$cbicon-football-U0xE916: "\e916";
$cbicon-freeplay-U0xE917: "\e917";
$cbicon-golf-U0xE918: "\e918";
$cbicon-history-U0xE919: "\e919";
$cbicon-home-U0xE91a: "\e91a";
$cbicon-horses-U0xE91b: "\e91b";
$cbicon-identification-U0xE91c: "\e91c";
$cbicon-image-U0xE91d: "\e91d";
$cbicon-inprogress-U0xE91e: "\e91e";
$cbicon-instagram-U0xE91f: "\e91f";
$cbicon-link-U0xE920: "\e920";
$cbicon-lock-U0xE921: "\e921";
$cbicon-menu-U0xE922: "\e922";
$cbicon-message-U0xE923: "\e923";
$cbicon-nba-U0xE924: "\e924";
$cbicon-nfl-U0xE925: "\e925";
$cbicon-nhl-U0xE926: "\e926";
$cbicon-plus-copy-U0xE927: "\e927";
$cbicon-plus-U0xE928: "\e928";
$cbicon-question-U0xE929: "\e929";
$cbicon-refresh-U0xE92a: "\e92a";
$cbicon-search-U0xE92b: "\e92b";
$cbicon-settings-U0xE92c: "\e92c";
$cbicon-share-U0xE92d: "\e92d";
$cbicon-solo-ticket-U0xE92e: "\e92e";
$cbicon-star-U0xE92f: "\e92f";
$cbicon-syndicates-U0xE930: "\e930";
$cbicon-tennis-U0xE931: "\e931";
$cbicon-ticket-U0xE932: "\e932";
$cbicon-transaction-back-U0xE933: "\e933";
$cbicon-transaction-forward-U0xE934: "\e934";
$cbicon-triangle-U0xE935: "\e935";
$cbicon-trophy-U0xE936: "\e936";
$cbicon-twitter-U0xE937: "\e937";
$cbicon-user-U0xE938: "\e938";
$cbicon-wallet-U0xE939: "\e939";
$cbicon-warning-U0xE93a: "\e93a";
$cbicon-whatsapp-U0xE93b: "\e93b";
$cbicon-world-U0xE93c: "\e93c";
$cbicon-wrc-U0xE93d: "\e93d";
$cbicon-youtube-U0xE93e: "\e944";

$cb-icon-chevron-down:               $cbicon-chevron-down-U0xE90b;
$cb-icon-chevron-up:                 $cbicon-chevron-up-U0xE90d;
$cb-icon-chevron-right:              $cbicon-chevron-right-U0xE90c;
$cb-icon-chevron-left:               $cbicon-chevron-back-U0xE90a;
$cb-icon-search:                     $cbicon-search-U0xE92b;


.cb-icon-esports {
  &:before {
    content: $cbicon-esports-controller-U0xE945;
  }
}
.cb-icon-uicon-cashout {
  &:before {
    content: $cbicon-cash-out-U0xE905;
  }
}
.cb-icon-notify {
  &:before {
    content: $cbicon-notify-U0xE900;
  }
}
.cb-icon-cbadge {
  &:before {
    content: $cbicon-cbadge-U0xE906;
  }
}
.cb-icon-baseline-settings-20px {
  &:before {
    content: $cbicon-settings-U0xE92c;
  }
}
.cb-icon-baseline-menu-24px {
  &:before {
    content: $cbicon-menu-U0xE922;
  }
}
.cb-icon-credit-card-solid {
  &:before {
    content: $cbicon-card-U0xE904;
  }
}
.cb-icon-baseline-arrow_back-24px {
  &:before {
    content: $cbicon-arrow-back-U0xE901;
  }
}
.cb-icon-my-tickets {
  &:before {
    content: $cbicon-ticket-U0xE932;
  }
}
.cb-icon-cash-out-notification {
  &:before {
    content: $cbicon-cash-out-U0xE905;
  }
}
.cb-icon-wallet {
  &:before {
    content: $cbicon-wallet-U0xE939;
  }
}
// .cb-icon-filter {
//   &:before {
//     content: $cb-icon-filter;
//   }
// }
.cb-icon-earth-1 {
  &:before {
    content: $cbicon-world-U0xE93c;
  }
}
.cb-icon-clock-alert-outline {
  &:before {
    content: $cbicon-clockalert-U0xE93f;
  }
}
.cb-icon-syndicates-join {
  &:before {
    content: $cbicon-join-U0xE93e;
  }
}
.cb-icon-stats {
  &:before {
    content: $cbicon-plus-copy-U0xE927;
  }
}
.cb-icon-info {
  &:before {
    content: $cbicon-warning-U0xE93a;
  }
}
.cb-icon-link {
  &:before {
    content: $cbicon-link-U0xE920;
  }
}
.cb-icon-star-outline {
  &:before {
    content: $cbicon-star-U0xE92f;
  }
}
.cb-icon-account-multiple {
  &:before {
    content: $cbicon-contributors-U0xE910;
  }
}
.cb-icon-delete {
  &:before {
    content: $cbicon-trash-U0xE941;
  }
}
.cb-icon-share-variant {
  &:before {
    content: $cbicon-share-U0xE92d;
  }
}
.cb-icon-back {
  &:before {
    content: $cbicon-chevron-back-U0xE90a;
  }
}
.cb-icon-chevron-right {
  &:before {
    content: $cbicon-chevron-right-U0xE90c;
  }
}
.cb-icon-chevron-left {
  &:before {
    content: $cbicon-chevron-back-U0xE90a;
  }
}
.cb-icon-chevron-down {
  &:before {
    content: $cbicon-chevron-down-U0xE90b;
  }
}
.cb-icon-chevron-up {
  &:before {
    content: $cbicon-chevron-up-U0xE90d;
  }
}
.cb-icon-arrow-left {
  &:before {
    content: $cbicon-arrow-back-U0xE901;
  }
}
.cb-icon-arrow-right {
  &:before {
    content: $cbicon-arrow-forward-U0xE902;
  }
}
.cb-icon-account-card-details {
  &:before {
    content: $cbicon-identification-U0xE91c;
  }
}
.cb-icon-email {
  &:before {
    content: $cbicon-message-U0xE923;
  }
}
.cb-icon-home {
  &:before {
    content: $cbicon-home-U0xE91a;
  }
}
// .cb-icon-warning {
//   &:before {
//     content: $cb-icon-warning;
//   }
// }
.cb-icon-question {
  &:before {
    content: $cbicon-question-U0xE929;
  }
}
.cb-icon-padlock {
  &:before {
    content: $cbicon-lock-U0xE921;
  }
}
.cb-icon-chat {
  &:before {
    content: $cbicon-chat-U0xE907;
  }
}
.cb-icon-Call {
  &:before {
    content: $cbicon-call-U0xE903;
  }
}
.cb-icon-triangle {
  &:before {
    content: $cbicon-triangle-U0xE935;
  }
}
.cb-icon-refresh {
  &:before {
    content: $cbicon-refresh-U0xE92a;
  }
}
.cb-icon-image {
  &:before {
    content: $cbicon-image-U0xE91d;
  }
}
.cb-icon-ext {
  &:before {
    content: $cbicon-external-U0xE914;
  }
}
.cb-icon-close-circle {
  &:before {
    content: $cbicon-close-U0xE90e;
  }
}
.cb-icon-close {
  &:before {
    content: $cbicon-close-U0xE90e;
  }
}
.cb-icon-check {
  &:before {
    content: $cbicon-check-U0xE908;
  }
}
.cb-icon-checkbox {
  &:before {
    content: $cbicon-checkbox-U0xE909;
  }
}
.cb-icon-plus-circle {
  &:before {
    content: $cbicon-pluscircle-U0xE940;
  }
}
.cb-icon-plus {
  &:before {
    content: $cbicon-plus-U0xE928;
  }
}
.cb-icon-trophy-variant {
  &:before {
    content: $cbicon-trophy-U0xE936;
  }
}
.cb-icon-star-favourite {
  &:before {
    content: $cbicon-star-U0xE92f;
  }
}
.cb-icon-my-tickets {
  &:before {
    content: $cbicon-ticket-U0xE932;
  }
}
.cb-icon-results-live {
  &:before {
    content: $cbicon-inprogress-U0xE91e;
  }
}
.cb-icon-search {
  &:before {
    content: $cbicon-search-U0xE92b;
  }
}
.cb-icon-results-complete {
  &:before {
    content: $cbicon-completed-U0xE90f;
  }
}
.cb-icon-icon-account-details-1 {
  &:before {
    content: $cbicon-solo-ticket-U0xE92e;
  }
}
.cb-icon-wallet-responsible {
  &:before {
    content: $cbicon-warning-U0xE93a;
  }
}
.cb-icon-information {
  &:before {
    content: $cbicon-warning-U0xE93a;
  }
}
.cb-icon-wallet-transaction {
  &:before {
    content: $cbicon-history-U0xE919;
  }
}
.cb-icon-wallet-deposit {
  &:before {
    content: $cbicon-deposit-U0xE912;
  }
}
.cb-icon-user-account {
  &:before {
    content: $cbicon-user-U0xE938;
  }
}
.cb-icon-syndicate-contributor {
  &:before {
    content: $cbicon-contributors-U0xE910;
  }
}
.cb-icon-hockey, .cb-icon-ice_hockey {
  &:before {
    content: $cbicon-nhl-U0xE926;
  }
}
.cb-icon-rugby {
  &:before {
    content: $cbicon-nfl-U0xE925;
  }
}
.cb-icon-freeplay {
  &:before {
    content: $cbicon-freeplay-U0xE917;
  }
}
.cb-icon-pools {
  &:before {
    content: $cbicon-pools-menu-U0xE942;
  }
}
.cb-icon-syndicates {
  &:before {
    content: $cbicon-syndicates-U0xE930;
  }
}
.cb-icon-nba {
  &:before {
    content: $cbicon-nba-amend_nba-U0xE943;
  }
}
.cb-icon-horses, .cb-icon-horse_racing {
  &:before {
    content: $cbicon-horses-U0xE91b;
  }
}
.cb-icon-greyhounds {
  &:before {
    content: $cbicon-dogs-U0xE913;
  }
}
.cb-icon-nfl {
  &:before {
    content: $cbicon-nfl-U0xE925;
  }
}
.cb-icon-soccer, .cb-icon-football {
  &:before {
    content: $cbicon-football-U0xE916;
  }
}
.cb-icon-wrc, .cb-icon-rally {
  &:before {
    content: $cbicon-wrc-U0xE93d;
  }
}
.cb-icon-tennis {
  &:before {
    content: $cbicon-tennis-U0xE931;
  }
}
.cb-icon-darts {
  &:before {
    content: $cbicon-darts-U0xE911;
  }
}
.cb-icon-golf {
  &:before {
    content: $cbicon-golf-U0xE918;
  }
}
.cb-icon-facebook {
  &:before {
    content: $cbicon-facebook-U0xE915;
  }
}
.cb-icon-twitter {
  &:before {
    content: $cbicon-twitter-U0xE937;
  }
}
.cb-icon-whatsapp {
  &:before {
    content: $cbicon-whatsapp-U0xE93b;
  }
}
.cb-icon-youtube {
  &:before {
    content: $cbicon-youtube-U0xE93e;
  }
}
.cb-icon-instagram {
  &:before {
    content: $cbicon-instagram-U0xE91f;
  }
}
