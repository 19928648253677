// Ticket list

// Ticket list item

.content.ticket-list-item {
  @apply flex flex-row px-custom-12 py-custom-16 justify-between items-center;
  @media screen and (max-width: 479px) {
    @apply pb-custom-12 pt-custom-12 mb-custom-12;
  }
  .left-block {
    @apply flex items-center;

    .ticket-list-icon {
      @apply flex mr-custom-12 p-custom-2 justify-center items-center rounded-full;
      @apply bg-highlight-bg text-highlight-color text-20;
      width: 30px;
      height: 30px;
    }
  }

  .ticket-item-type {
    @apply mb-custom-4 text-15 font-semibold text-text-color;
    .username {
      @apply cursor-pointer text-reduced-color; 
    }
    @media screen and (max-width: 479px) {
      @apply text-14;
    }
  }

  .freeplay {
    @apply text-freeplay-bg;
  }

  .ticket-card-details {
    @apply text-12 leading-17 mr-custom-12 mb-custom-2;
  }
}

// Avatars

.avatar {
  width: 30px;
  height: 30px;
  @apply mr-custom-12 rounded-full cursor-pointer;
}


// Single ticket
.container-full {
  @apply flex flex-row items-center px-custom-16;
  margin-right: -16px;
  margin-left: -16px;
  min-height: 41px;
}

// 1. Ticket result selections


.preview-selections, .preview-selections-2 {
  .coupon-btn.cs.preview {
    cursor: default;
    @apply w-auto mr-custom-10 mb-custom-10;
    @apply border border-selection-default-extra;
    @media screen and (max-width: 479px) {
      min-width: 20%;
    }
  }

  .coupon-btn._1x2.preview {
    cursor: default;
    @apply w-auto mr-custom-10 ml-0  mb-custom-10;
    min-width: 60px;
  }

  .coupon-btn {
    @apply flex justify-center items-center text-13 ml-0 mb-custom-8 font-medium rounded-none relative;
    @apply border border-solid border-transparent p-custom-4;
    @apply bg-selection-bg text-selection-color;
    height: 34px;
    @media screen and (max-width: 479px) {
      height: 31px;
    }
    &.active {
      @apply bg-selection-active-bg text-selection-active-color font-semibold;
    }

    &._1x2 {
      width: 33.3%;
    }

    &.cs.preview, &._1x2.preview {
      &.WIN, &.success {
        @apply border border-selection-win-extra;
      }
      &.LOSS, &.error {
        @apply border border-selection-lost-extra;
      }
      &.DRAW {

      }
    }
    &.WIN, &.success {
      @apply bg-selection-bg text-selection-win-color;
      @apply border border-selection-win-extra;
      @apply font-semibold;
      cursor: default;
      &:after {
        @apply absolute top-0 left-0 w-0 h-0;
        content: "";
        border-top-width: 10px;
        border-top-style: solid;
        border-top-color: var(--selection-win-extra);
        border-right: 10px solid transparent;
      }
    }
    &.LOSS, &.error {
      @apply bg-selection-bg text-selection-lost-extra;
      @apply border border-selection-lost-extra;
      cursor: default;
      &:after {
        @apply absolute bottom-0 right-0 w-0 h-0;
        content: "";
        border-bottom-width: 10px;
        border-bottom-style: solid;
        border-bottom-color: var(--selection-lost-extra);
        border-left: 10px solid transparent;
      }
    }
    &.DRAW {
      @apply bg-selection-draw-bg text-selection-draw-color;
      // cursor: default;
    }
  }

  .coupon-btn.in_play {
    cursor: default;
    @apply border-inplay-bg;
  }

  .preview-horse-selection {
    @apply relative flex w-full mb-custom-14 py-custom-8 px-custom-10 items-center;
    @apply border border-transparent rounded-container;
    max-width: 49%;
    min-height: 50px;
  }
}

// Ticket breakdown

.component-header-row.ticket-breakdown {
  @apply mb-custom-24;
  .widget-header {
    @apply text-17 leading-23 font-semibold text-left;
    &.racing {
      @apply flex flex-row items-center;
      .extract {
        @apply ml-auto;
        .link {
          @apply text-12 text-link-color underline cursor-pointer;
        }
      }
    }
  }
}

.ticket_syndicate_img {
  @apply mr-custom-14;
  img {
    border-radius: 20px;
    width: 32px;
    height: 32px;
    object-fit: cover;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
}

.cashout-title {
  @apply flex flex-row justify-start items-center;
  @apply bg-info-bg text-info-color p-custom-16;
}

.ticket-col-details {
  @apply flex justify-center items-center rounded-container;
  @apply border-t border-global-border;
  margin-right: -16px;
  margin-left: -16px;

  .ticket-col {
    @apply py-custom-12;
    &.w-col {
      @apply relative float-left px-custom-10;
      min-height: 1px;
    }
    &.w-col-4 {
      @apply w-1/3;
    }
    &.w-col-6 {
      @apply w-2/3;
    }
    &.left-col {
      @apply rounded-tl-container rounded-bl-container;
    }
    &.center-col {
      @apply border-r border-l border-global-border;
    }
    &.right-col {
      @apply rounded-tr-container;
    }
    .ticket-label {
      @apply text-center text-reduced-color mb-custom-4;
      &.value {
        @apply text-container-color font-semibold;
      }
    }
  }
}

// offer transactions

.content.cash-out-breakdown {
  @apply mb-0 px-0 shadow-none py-custom-16;

  .cash-out-timeline-bar {
    @apply absolute bg-inplay-bg;
    left: 7px;
    width: 3px;
    height: 95%;
  }

  .cash-out-portion {
    @apply flex flex-row justify-between mb-custom-18;
    &.last {
      @apply mb-0;
    }

    .cash-out-portion-left {
      @apply flex;

      .cash-out-dot {
        @apply relative border-3 bg-inplay-bg border-container-bg mr-custom-16 rounded-full; // TODO: Check if border is all good
        width: 18px;
        height: 18px;
      }
    }

    .cash-out-timestamp {
      @apply text-11 text-reduced-color;
    }
  }
}

// ticket share

#ticketshare {
  .links {
    :first-child:nth-last-child(1),
    :first-child:nth-last-child(1) ~ .social-network-link {
      width: 100%;
    }

    :first-child:nth-last-child(2),
    :first-child:nth-last-child(2) ~ .social-network-link {
      width: 50%;
    }

    :first-child:nth-last-child(3),
    :first-child:nth-last-child(3) ~ .social-network-link {
      width: 33.3333%;
    }

    :first-child:nth-last-child(4),
    :first-child:nth-last-child(4) ~ .social-network-link {
      width: 25%;
    }

    .social-network-link {
      float         : left;
      height        : 41px;
      display       : flex;
      justify-content: center;
      align-items   : center;
      text-align    : center;
      line-height   : 35px;
      font-size     : 18px;
      cursor        : pointer;

      i {
        font-size: 16px;
      }
      &:first-of-type {
        border-top-left-radius   : 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius   : 4px;
        border-bottom-right-radius: 4px;
      }

      &.whatsapp {
        @apply bg-share-wa-bg text-share-wa-color;
      }

      &.twitter {
        @apply bg-share-tw-bg text-share-tw-color;
      }

      &.facebook {
        @apply bg-share-fb-bg text-share-fb-color;
      }
    }

    &.small {
      .social-network-link {
        height        : 32px;
      }
    }
  }

  .share-cashout-text {
    display: none;
    #offer & {
      display: inline-block;
    }
  }
  .share-ticket-text {
    #offer & {
      display: none;
    }
  }
}

// Global ticket statuses

.ticket-status {
  @apply rounded-b-nav_link text-14 font-semibold text-right;

  .status {
    @apply flex px-custom-16 py-custom-4 justify-center items-center text-12 font-semibold;
    border-radius: 20px; // needs to be added to global border radius
    &.VOID {
      @apply bg-voided-bg text-voided-color;
    }

    &.placed, &.OPEN {
      @apply bg-placed-bg text-placed-color;
    }

    &.RUNNING, &.IN_PLAY {
      @apply bg-inplay-bg text-inplay-color;
    }

    &.funded {
      @apply text-14 text-right;
      .percentage {
        @apply text-inplay-bg; // TODO: Should be ok, to check
      }
    }

    &.offer {
      @apply bg-offer-bg text-offer-color text-center;
    }

    &.winning {
      @apply bg-offer-bg text-offer-color text-center;
    }

    &.cashed-out {
      @apply bg-offer-bg text-offer-color text-center;
    }

    &.REFUNDED {
      @apply bg-refunded-bg text-refunded-color;
    }
  }
}

// my share

.content.widget {
  &.my-share {
    @apply pb-custom-10 items-stretch;
  }

  .my-share-item-group {
    .my-share-item {
      @apply flex mb-custom-10 pb-custom-10 justify-between items-end;
      @apply border-b border-global-border;

      .my-share-label {
        @apply mt-custom-7;
      }
      .my-share-value {
        @apply font-semibold;
      }

      &.last {
        @apply border-b-0 mb-0;
      }
    }
  }
}

.content.segmented-control.w-tab-menu {
  &.winnings {
    @apply mb-0 border-b border-global-border rounded-none justify-between shadow-none;
  }
  .tab-link-2 {
    @apply flex flex-col justify-center items-center rounded-nav_link;
    @apply px-custom-14 py-custom-6 mr-custom-2;
    @apply text-13 font-semibold;
    @apply bg-container-bg;
    @apply text-reduced-color;

    &.disabled {
      @apply opacity-50 pointer-events-none;
    }
    &.prize-breakdown {
      @apply text-center w-1/2;
    }
    &.w--current {
      @apply bg-success-bg text-success-color;
    }
  }
}


.group-ticket-owned {
  .title {
    @apply text-16 mb-custom-24 text-headings-color;
  }
  .description {
    @apply text-11;
  }
  .have-voted {
    @apply flex flex-col leading-1 mb-custom-8;
    @apply text-16 text-headings-color;
    .big {
      @apply text-23 mb-custom-8;
    }
  }
}

.group-ticket-vote {
  .title {
    @apply text-16 mb-custom-16 text-headings-color;
  }
  .description {
    @apply text-11 mb-custom-16;
  }
}
