.w-tab-menu {
  @apply relative;
  // the main colour is inherited from content

  .w-tab-link {
    @apply relative text-left cursor-pointer px-custom-30 py-custom-9;
    vertical-align: top;
  }
  .tab-link {
    white-space: nowrap;
    @apply flex flex-col mr-custom-2 px-custom-14 py-custom-6 justify-center items-center rounded-nav_link text-13 font-semibold cursor-pointer relative;
    @apply bg-submenu-item-bg text-submenu-item-color;
    &.w--current {
      @apply rounded-container shadow-tab-menu cursor-default;
      @apply bg-submenu-item-active-bg text-submenu-item-active-color;
      &.toggle {
        @apply cursor-pointer;
      }
    }

    &.racing-leg-tab {
      @apply w-full px-custom-8 text-center;
    }

    &.mobile-only {
      @apply flex md:hidden;
    }

    .new-feature-badge {
      @apply flex flex-row w-auto bg-container-reduced-color text-primary-color shadow-none text-center;
      @apply absolute px-custom-2 rounded-container;
      @apply border border-primary-color;
      @apply text-11;
      max-width: 48px;
      top: -6px;
      &.badge-right {
        left: calc(100% - 14px);
      }
    }

    .leg-indiciator-badge {
      @apply flex absolute justify-center items-center rounded-full text-11;
      @apply bg-tag-bg text-tag-color; // Same as tag
      left: 83.5px;
      top: -8px;
      width: 18px;
      height: 18px;
      &.racing {
        left: auto;
        right: -2.9px;
        z-index: 2;
        width: 17px;
        height: 17px;
        font-size: 10px;
      }
    }

    &.reverse {
      @apply bg-submenu-item-reverse-bg text-submenu-item-reverse-color;
      &.w--current {
        @apply bg-submenu-item-reverse-active-bg text-submenu-item-reverse-active-color;
      }
    }

    &.syndi-cont {
      width: 25%;

      &.syndi-cont-3 {
        width: 33%;
        text-align: center;
      }

      &.syndi-cont-2 {
        width: 50%;
        text-align: center;
      }

      &.syndi-cont-1 {
        width: 100%;
        text-align: center;
      }

      &.disabled {
        @apply pointer-events-none opacity-60;
      }
    }
  }
  .w-inline-block {
    @apply max-w-full inline-block /*whitespace-pre*/;
  }

  &.reverse {
    @apply bg-submenu-reverse-bg;
  }
}
.w-tab-content {
  @apply block relative overflow-hidden;
}
