.content.contribution-confirmed {
  @apply flex flex-row justify-between items-center;
  @apply mb-custom-16 p-custom-16 pl-custom-10;
  @apply border-t-4 border-success-bg;

  &.cross-sell {
    @apply flex-col items-start h-auto;
  }
  height: 61px;

  &.syndicates {
    @media (max-width: 575px) {
      @apply flex flex-col;
      @apply px-custom-11 pt-custom-6 pb-custom-10;
      height: 89px;
      // Fix mobile
      @apply justify-between items-start;

    }
  }

  &.nh {
    @apply h-auto;
  }
  .confirm-text {
    @apply flex items-center;
    @media (max-width: 575px) {
      @apply  w-full;
    }

    .ticket-confirm-check {
      @apply flex justify-center items-center rounded-full mr-custom-12 p-custom-2;
      @apply text-13 bg-primary-color;
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      i {
        @apply text-text-color pt-custom-2;
      }
    }

    .widget-header {
      @apply text-17 leading-23 font-semibold text-left;
      &.confirm {
        @apply mb-0;
        min-width: 200px;
        max-width: 240px;
        @media (max-width: 575px) {
          @apply text-15;
          max-width: 100%;
        }
        &.full {
          max-width: 100%;
        }
      }
    }
  }

  .link-label {
    @apply text-14 text-right text-link-color;
  }
}
