/**
  1.0 LIVE SCORES WIDGET (RESULTS PAGE)
*/

#widget-live-scores {
  .scores-list-container {
    @apply flex flex-col w-full;
    .scores-item {
      @apply flex flex-row w-full justify-start items-center px-custom-8 py-custom-6 pl-0;
      @apply bg-card-bg text-card-color font-medium;
      //@apply border-t border-global-border;
      min-height: 41px;

      &.last {
        @apply rounded-b-container;
      }

      .match {
        @apply flex flex-row whitespace-nowrap items-center justify-start w-9/12;
      }

      .sport_icon {
        @apply flex flex-col justify-center items-center;
        width: 30px;
        height: 30px;
      }

      .competitors {
        @apply flex flex-col font-normal leading-16 mr-auto text-12;
        width: 100px;
      }

      .scores {
        @apply flex flex-col leading-16 p-custom-4 text-12;
      }

      .period {
        @apply flex items-center justify-end w-3/12 text-12;
      }
    }
  }
}

// Top Captains - right side

.widget-items {
  @apply flex w-full justify-between flex-wrap;
  margin-bottom: -14px;

  .list-item {
    @apply flex mb-custom-14 py-custom-16 flex-col justify-between items-center rounded-container whitespace-nowrap cursor-pointer overflow-hidden;
    &:hover {
      background: rgba(0,0,0,0.03);
    }
    width: 48%;
    transition: all 200ms ease;
    &.club {
      @apply text-center;
    }

    .list-item-avatar-holder {
      @apply flex justify-center relative;
    }

    .feature-avatar {
      @apply mx-auto border border-global-border rounded-full mb-custom-8;
      width: 38px;
      height: 38px;
      min-height: 38px;
      min-width: 38px;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .tag {
      left: 26px;
      top: 0;
    }

    .club-member {
      @apply flex flex-col items-start;
      &.center-align {
        @apply items-center;
      }

      .captain-name {
        @apply flex overflow-hidden items-center font-semibold leading-17 text-center whitespace-pre-line text-14 text-text-color;
        height: 25px;
      }

      .tertiary-info {
        @apply text-13 text-center text-reduced-color;
      }
    }
  }
}

// syndicate cross-sell

.content.syndi-x-sell {
  @apply px-custom-16 pt-custom-24 pb-custom-16;
  min-height: 260px;

  .x-sell-cards {
    @apply mt-custom-14;

    .syndi-join-card.x-sell {
      @apply mb-custom-16 px-custom-12 pb-custom-14 shadow-btn-shadow;
      @apply bg-container-bg text-container-color text-13 leading-17;
      @apply border border-global-border;
      transition: all 300ms ease;
      &:hover {
        background: rgba(0,0,0,0.03);
      }
      .syndi-join-top-row {
        @apply relative flex pb-custom-14 justify-between items-center;
        @apply border-b border-global-border;
        .captain-information {
          @apply relative flex items-center;
          width: 70%;

          .captain-image {
            @apply mr-custom-14 object-cover;
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            border-radius: 20px; // ???
          }
          .info {
            @apply w-full;
          }
          .join-card-creator {
            @apply leading-22 font-semibold text-container-color;
          }
          .join-card-prize {
            @apply font-normal;
            .highlight {
              @apply font-semibold;
            }
          }
        }
        .funded-percentage {
          @apply text-14 font-semibold flex flex-col justify-center items-center;
          &.highlight {
            @apply text-accent-color;
          }
        }
      }
      .join-card-bottom-row {
        @apply flex mt-custom-14 mb-custom-4 justify-between items-center;

        .join-card-cost {
          @apply flex flex-row justify-start items-center font-normal;
        }
        .contributors-div {
          @apply flex justify-end items-center;
          .fa-user-friends {
            // the icon
            @apply text-reduced-color;
          }
        }
      }
    }
  }
}

 // new users homepage widget

.content {
  &.learn-toplay {
    @apply px-custom-16 pt-custom-24 pb-custom-16;
    .component-header-row {
      @apply flex w-full mb-custom-16 justify-between items-center;
      .widget-header {
        @apply text-17 leading-23 font-semibold text-left;
        &.small {
          @apply text-15 font-semibold;
        }
      }
    }
    .what-is-element {
      @apply flex mb-custom-24 justify-start items-center rounded-container bg-contain bg-no-repeat p-custom-16;
      @apply bg-highlight-bg text-highlight-color;
      min-height: 100px;
      background-position: 110% 50%;

      &.rev {
        @apply justify-end text-right;
        background-position: 0% 50%;
      }
      &._1 {
        background-image: var(--learn-toplay-1-bg);
      }
      &._2 {
        background-image: var(--learn-toplay-2-bg);
      }
      &._3 {
        background-image: var(--learn-toplay-3-bg);
      }
      &._4 {
        background-image: var(--learn-toplay-4-bg);
      }
    }
  }
}

// captain have more to join

.content {
  &.desktop-not-member-contribute {
    @apply mb-custom-24 py-custom-24 px-custom-16;

    .join-not-a-member-header {
      @apply flex flex-row-reverse items-center mb-custom-24 pb-custom-24;
      @apply border-b border-global-border;

      .widget-header {
        @apply text-17 leading-23 font-semibold text-left;
      }

      .position-fix {
        @apply relative;
        .avatar-img {
          @apply rounded-full mr-custom-16 object-cover;
          width: 60px;
          height: 60px;
          min-width: 60px;
          min-height: 60px;
        }
        .tag.xsell {
          left: 42px;
          width: 29px;
          height: 28px;
          font-size: 15px;
          top: 0;
        }
      }
    }
  }
}
