.cb-notifications {
    position: fixed;
    z-index : 99999;
    right   : 0;
    top     : 0;
    left    : 0;
    bottom  : 0;
    padding : 20px;
    // background: rgba(0,0,0,0.15);
    pointer-events: none;
    @media (max-width: 767px){
      padding: 0;
    }
  .notification-box {
    pointer-events: all;
    @apply shadow-content-shadow dark:shadow-dark-content-shadow rounded-container ml-auto p-custom-16;
    @apply bg-container-bg text-container-color;
    width: 20rem;

    .title {
      @apply text-17 font-semibold;
    }

    .content-links {
      @apply flex justify-start items-center mt-custom-16;
    }
  }
}
