@mixin headerBg($path, $ext: "jpg") {
  $desk_path: "#{$path}_desktop.#{$ext}";
  //$mob_path: "#{$path}_mobile.#{$ext}";
  background-image: url("#{$desk_path}"), linear-gradient(84deg, #000, rgba(34, 34, 34, 0.09) 58%);

  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat, repeat;
}

@mixin feature-pool-bg($img, $img_static, $type: 'png', $type_static: 'png') {
  background-image: linear-gradient(3deg, #180133, rgba(0, 0, 0, 0.09) 55%), url('#{$img}.webp'), linear-gradient(90deg, #190235 9%, rgba(25, 2, 53, 0.64) 35%, rgba(25, 2, 53, 0.32) 86%, rgba(25, 2, 53, 0.47)), url('#{$img_static}.webp');
  .no-webp & {
    background-image: linear-gradient(3deg, #180133, rgba(0, 0, 0, 0.09) 55%), url('#{$img}.#{$type}'), linear-gradient(90deg, #190235 9%, rgba(25, 2, 53, 0.64) 35%, rgba(25, 2, 53, 0.32) 86%, rgba(25, 2, 53, 0.47)), url('#{$img_static}.#{$type_static}');
  }
}

@mixin freeplayLogo {
  @apply text-freeplay-bg; // Should be the F2P color (yellow in general)
}

@mixin syndicateHelpImage($path) {

  background-image: url("#{$path}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;

  @media (max-width : 767px) {
    display: none;
  }
}

// animation - ghost el

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
