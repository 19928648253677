:root {
  --cropper-content-bg: var(--container-bg);
  --cropper-content-color: var(--container-color);

  --cropper-success-color: #04c559;
  --cropper-sizebar-selector-color: #2197ff;
  --cropper-sizebar-selector-border-color: #525661;
  --cropper-sizebar-selector-shadow: 1px 1px 3px 0 rgb(0 0 0 / 22%);
  --cropper-sizebar-bg: #ededed;
}

// ----------------------------



#cropper {
  border-radius: 4px;
  font-family: sans-serif;
  padding: 20px;
  display: flex;
  -webkit-display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;


  @media(max-width: 480px) {
    padding: 30px 20px 30px;
    position: fixed;
    overflow: hidden;
    background: var(--cropper-content-bg);
    width: 100%;
    z-index: 1000;
    top: 0px;
    left: 0px;
    height:100%;
    justify-content: center;
    -webkit-justify-content: center;
  }


  .crop-area {
    margin:  10px auto;
    height: 250px;
    width: 200px;
    border-radius: 4px;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
    .small {
      position: absolute;
      font-size: 16px;
      left: 7px;
      top: 217px;
    }
    .big {
      position: absolute;
      font-size: 26px;
      right: 7px;
      top: 211px;
    }
  }
  .crop-btn {
    position:relative;
    z-index:20;
  }
}

.croppie-container {
  width: 100%;
  height: 100%;
  .cr-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    max-height: none;
    max-width: none;
  }

  .cr-boundary {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .cr-viewport,
  .cr-resizer {
    position: absolute;
    border: 2px solid var(--cropper-content-bg);
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0 2000px 2000px var(--cropper-content-bg);;
    z-index: 0;
  }
  .cr-resizer {
    z-index: 2;
    box-shadow: none;
    pointer-events: none;
  }

  .cr-resizer-vertical,
  .cr-resizer-horisontal {
    position: absolute;
    pointer-events: all;
  }

  .cr-resizer-vertical::after,
  .cr-resizer-horisontal::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    border: 1px solid var(--cropper-content-color);
    background: var(--cropper-sizebar-bg);
    width: 10px;
    height: 10px;
    content: '';
  }
  .cr-resizer-vertical {
    bottom: -5px;
    cursor: row-resize;
    width: 100%;
    height: 10px;
  }

  .cr-resizer-vertical::after {
    left: 50%;
    margin-left: -5px;
  }

  .cr-resizer-horisontal {
    right: -5px;
    cursor: col-resize;
    width: 10px;
    height: 100%;
  }

  .cr-resizer-horisontal::after {
    top: 50%;
    margin-top: -5px;
  }

  .cr-original-image {
    display: none;
  }

  .cr-vp-circle {
    border-radius: 50%;
  }

  .cr-overlay {
    z-index: 1;
    position: absolute;
    cursor: move;
    touch-action: none;
  }

  .cr-slider-wrap {
    width: 70%;
    margin: 15px 5px 15px 23px;
    text-align: center;
  }

  .cr-image,
  .cr-overlay,
  .cr-viewport {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}


.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}


/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/

.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; *//*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: var(--cropper-content-color);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: -6px;
  background: var(--cropper-sizebar-selector-color);
  border: 1px solid var(--cropper-sizebar-selector-border-color);
  box-shadow: var(--cropper-sizebar-selector-shadow);
}

.cr-slider:focus {
  outline: none;
}


.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  border: 0;
  border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--cropper-sizebar-selector-color);
  border: 1px solid var(--cropper-sizebar-selector-border-color);
  box-shadow: var(--cropper-sizebar-selector-shadow);
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid var(--cropper-content-bg);
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent;/*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent;/*remove default tick marks*/
}
.cr-slider::-ms-fill-lower {
  background: var(--cropper-content-color);
  border-radius: 10px;
}
.cr-slider::-ms-fill-upper {
  background: var(--cropper-content-color);
  border-radius: 10px;
}
.cr-slider::-ms-thumb {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--cropper-content-bg);
  margin-top:1px;
}
.cr-slider:focus::-ms-fill-lower {
  background: var(--cropper-content-color);
}
.cr-slider:focus::-ms-fill-upper {
  background: var(--cropper-content-color);
}


