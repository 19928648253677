.btn {
  @apply flex justify-center items-center rounded-btn-radius font-semibold h-btn-height shadow-btn-shadow;
  transition: all 250ms ease;

  &.w-button {
    padding: 9px 15px;
    white-space: nowrap;
    @apply text-b-primary-color border-0 cursor-pointer;
  }

  &.cash-out {
    @apply w-2/3 mr-auto ml-auto;
  }

  &._48 {
    width: 48%;
  }

  &._31 {
    width: 31%;
  }

  &._21 {
    width: 21% !important;
  }

  &.primary {
    @apply bg-b-primary-bg text-b-primary-color;
  }

  &.secondary {
    @apply bg-b-secondary-bg text-b-secondary-color;
  }

  &.tertiary {
    @apply text-link-color bg-b-tertiary-bg;
  }

  &.link {
    @apply shadow-none text-link-color text-13;
  }

  &.freeplay {
    @apply bg-freeplay-bg text-freeplay-color;
  }

  &.register {
    @apply bg-freeplay-bg text-freeplay-color;
  }

  &.betslip {
    @apply bg-container-bg text-text-color;
    color: var(--color-text);
    width: 32%;
    &.dual {
      width: 48%;
    }
    &.secondary {
      @apply text-link-color;
    }
    &.small-text {
      @apply text-11;
    }
    .full & {
      width: 50%;
      @apply mx-custom-5;
    }
  }

  &.fp-token {
    @apply text-center;
    min-width: 140px;
    @media all and (max-width: 479px) {
      min-width: 80px;
      width: 50%;
    }
  }

  &.icon-before {
    i {
      @apply mr-custom-12;
    }
  }

  &.icon-after {
    i {
      @apply ml-custom-12;
    }
  }

  // Extras

  &:hover {
    filter: brightness(90%);
  }

  &.primary.feature-pool, &.secondary.feature-pool {
    width: 49%;
  }

  &.blog-card {
    background-color: var(--b-blog-card-bg);
    color: var(--b-blog-card-text);
    max-width: 110px;
  }

  &.w-49 {
    width: 49%;
  }

  &.height-increase {
    @apply text-17;
    height: 51px;
  }

  &.small {
    @apply px-custom-6 text-13;
    height: 31px;
    line-height: 9px
  }

  .svg-spinner {
    @apply animate-spin mr-custom-12 h-5 w-5 text-svg-spinner;
  }

  &.flat {
    @apply rounded-none shadow-none;
  }
}

.btn-pri {
  @apply flex justify-center items-center bg-b-primary-bg text-b-primary-color font-semibold rounded-container cursor-pointer;
  height: 32px;
  &.success {
    @apply w-auto mr-custom-10 px-custom-12 bg-highlight-bg text-accent-color text-12;
  }
  &.w-49 {
    @apply mr-0;
    width: 49%;
  }
}

a.link {
  @apply text-link-color cursor-pointer;
}

.direct-url-share {
  @apply flex flex-row justify-center items-center rounded-container font-semibold cursor-pointer px-custom-12 w-auto leading-1;
  @apply bg-b-share-bg text-b-share-color;
  height: 32px;
  .full-w & {
    @apply w-full mr-0;
  }
}

// Sharing buttons
.sharing-buttons {
  @apply flex w-full justify-end;
  transition: all 400ms;

  .sharing-button-item {
    @apply flex mr-custom-9 justify-center items-center;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    background-size: auto auto;
    background-position: 0 0, 0 0;
    border-radius: 20px;
    // Since this are brand colors it's un-useful to have them as variables
    i {
      @apply text-14 relative;
      top: 1px;
    }
    &.wa {
      @apply text-17 text-share-wa-color;
      background-image: var(--share-wa-bg);
    }
    &.twitter {
      @apply text-17 text-share-tw-color;
      background-image: var(--share-tw-bg);
    }
    &.fb {
      @apply text-17 text-share-fb-color;
      background-image: var(--share-fb-bg);
    }
    &.link {
      @apply flex w-auto pr-custom-12 pl-custom-14 justify-center items-center cursor-pointer;
      @apply bg-share-link-bg text-share-link-color;
      .link-icon {
        transform: rotate(45deg);
      }
      &.completed {
        @apply cursor-default;
      }
    }
    &:hover {
      @apply cursor-pointer;
      filter: brightness(1.1);
    }
  }
}

// loading button

button, a.btn {
  position: relative;

  &.is-loading {
    color: transparent !important;
    &:hover{
      color: transparent !important;
    }
    &:before {
      content: ' ';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #000;
      border-top-color: #DDD;
      animation: spinner .6s linear infinite;
      font-size: 14px;
    }
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}


// Dropdown

select.select-dropdown {
  @apply text-13 rounded-container justify-center items-center focus:ring-opacity-50;
  @apply bg-select-bg text-select-color;
  @apply border-global-border focus:border-global-border-reduced focus:ring-global-border-reduced;

  &.mobile-select {
    @apply font-semibold shadow-btn-shadow border-none rounded-btn-radius focus:border-transparent focus:ring-transparent focus:ring-0 outline-none;
    @apply bg-select-bg text-select-color;
    &.small {
      @apply text-11;
      padding-right: 1.7rem;
    }
  }
}


// Chekcbox

.checkbox {
  @apply mr-custom-16 rounded-container border-transparent focus:border-transparent focus:ring-1 focus:ring-offset-2 mt-custom-3;
  @apply bg-checkbox-bg text-checkbox-color;
  @apply border-global-border focus:bg-checkbox-bg focus:ring-global-border-reduced;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}

// Inputs

.text {
  @apply block w-full rounded-container border text-container-color p-custom-8 focus:ring focus:ring-opacity-10 outline-none;
  @apply border-global-border focus:border-global-border-reduced focus:ring-global-border-reduced;
  @apply bg-input-bg text-input-color;
  &.icon-before {
    text-indent: 24px;
  }
}

