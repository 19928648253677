#offer-slider {
  float: left;
  display: block;
  margin: 1rem 0;

  .noUi-target,
  .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .noUi-target {
    position: relative;
    direction: ltr;
  }
  .noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0;
      height: 16px;
      background: #eaffa9;
    }
  }
  .noUi-connect {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: transform;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
  }
  .noUi-origin {
    position: absolute;
    height: 0;
    width: 0;
    will-change: transform;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
  }
  .noUi-handle {
    position: relative;
    z-index: 1;
  }
  .noUi-state-tap .noUi-connect,
  .noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
  }
  .noUi-state-drag * {
    cursor: inherit !important;
  }

  .noUi-base,
  .noUi-handle {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .noUi-horizontal {
    height: 7px;
  }
  .noUi-horizontal .noUi-handle {
    @apply shadow-btn-shadow;
    width: 30px;
    height: 30px;
    left: -15px;
    top: -14px;
    border-radius: 50%;
  }
  .noUi-vertical {
    width: 10px;
  }
  .noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
  }

  .noUi-target {
    @apply bg-slider-connect;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    box-shadow: 0 3px 6px -5px #BBB;
  }
  .noUi-connect {
    @apply bg-offer-bg;
    -webkit-transition: background 450ms;
    transition: background 450ms;
  }
  .noUi-draggable {
    cursor: ew-resize;
  }
  .noUi-vertical .noUi-draggable {
    cursor: ns-resize;
  }
  .noUi-handle {
    @apply bg-offer-bg;
    border-radius: 3px;
    cursor: default;
  }
  .noUi-active {
    transition: transform .26667s ease-out,opacity .26667s ease-out,background-color .26667s ease-out,-webkit-transform .26667s ease-out;
  }
  .noUi-vertical .noUi-handle:before,
  .noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
  }
  .noUi-vertical .noUi-handle:after {
    top: 17px;
  }

  [disabled] .noUi-connect {
    @apply bg-offer-bg;
  }
  [disabled].noUi-target,
  [disabled].noUi-handle,
  [disabled] .noUi-handle {
    cursor: not-allowed;
  }

  .noUi-pips,
  .noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .noUi-pips {
    position: absolute;
    color: #999;
  }

  .noUi-value {
    position: absolute;
    text-align: center;
  }
  .noUi-value-sub {
    color: #ccc;
    font-size: 10px;
  }

  .noUi-marker {
    position: absolute;
    background: #CCC;
  }
  .noUi-marker-sub {
    background: #AAA;
  }
  .noUi-marker-large {
    background: #AAA;
  }

  .noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
  }
  .noUi-value-horizontal {
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
  }
  .noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 7px;
  }
  .noUi-marker-horizontal.noUi-marker-sub {
    height: 7px;
  }
  .noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
  }

  .noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
  }
  .noUi-value-vertical {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
    padding-left: 25px;
  }
  .noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
  }
  .noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
  }
  .noUi-marker-vertical.noUi-marker-large {
    width: 15px;
  }
  .noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
  }
  .noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
  }
  .noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
  }

  /* Marks */
  $marks: 10;

  @mixin marks-generator {
    @for $i from 1 through $marks {
      .mark_#{$i} .noUi-base::before {
        width: 10% * $i;
      }
    }
  }

  @include marks-generator;
}

.markers{
  display: block;
  width: 100%;
  background: transparent;
  position: relative;
  float: left;
  margin: 0 1px;
  margin-bottom: 20px;
  margin-top: 16px;

  > .marker {
    opacity: 1;
    height: 1px;
    display: block;
    float: left;
    width: 10%;
    position: relative;
    &:after {
      @apply bg-slider-inactive;
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      right: -2px;
      top: 0;
      border-radius: 100%;
    }
  }
}

.cashout-bar{
  width: 95%;
  margin: 0px auto;
  position: relative;
  &:before {
    content    : '0%';
    position   : absolute;
    bottom     : -66px;
    left       : 0;
    z-index    : 700;
    @apply font-semibold text-12 text-reduced-color;
  }
  &:after {
    content    : '100%';
    position   : absolute;
    right      : 0;
    bottom     : -66px;
    z-index    : 998;
    @apply font-semibold text-12 text-reduced-color;
  }

  .cash-out-min{
    @apply bg-offer-bg;
    border-radius: 3px;
    box-shadow: rgb(187, 187, 187) 0px 3px 6px -5px;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 7px;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    float:left;
    width: 10%;
  }
  .cashed-out{
    @apply bg-slider-inactive;
    border-radius: 4px;
    box-shadow: rgb(187, 187, 187) 0px 3px 6px -5px;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 7px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    float:right;
  }

  .cashed-out + nouislider{

    .noUi-target{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }
}
