// Banner element extracted for reuse
.pool-banner {
  @apply flex flex-col items-start justify-center;
  padding: 28px 16px 24px;
  &.headline {
    @apply justify-between border-0 relative;
    min-height: 260px;
    // pool specific images
    &[class^="FOOTBALL_CORRECT_SCORE"], &[class*=" FOOTBALL_CORRECT_SCORE"] {
      @include headerBg($pool-CORRECT_SCORE-background, 'jpg');
    }
    &[class^="FOOTBALL_HDA"], &[class*=" FOOTBALL_HDA"] {
      @include headerBg($pool-HDA-background, 'jpg');
    }
    &[class^="FOOTBALL_OVER_UNDER"], &[class*=" FOOTBALL_OVER_UNDER"] {
      @include headerBg($pool-OVER_UNDER-background, 'jpg');
    }
    &[class^="FOOTBALL_ASIAN_HANDICAP"], &[class*=" FOOTBALL_ASIAN_HANDICAP"] {
      @include headerBg($pool-ASIAN_HANDICAP-background, 'jpg');
    }
    &[class^="FOOTBALL_BTTS"], &[class*=" FOOTBALL_BTTS"] {
      @include headerBg($pool-BTTS-background, 'jpg');
    }
    &[class^="TENNIS_H2H"], &[class*=" TENNIS_H2H"] {
      @include headerBg($pool-H2H-background, 'jpg');
    }
    &[class^="TENNIS_CORRECT_SCORE_3"], &[class*=" TENNIS_CORRECT_SCORE_3"] {
      @include headerBg($pool-CORRECT_SCORE_3-background, 'jpg');
    }
    &[class^="TENNIS_CORRECT_SCORE_5"], &[class*=" TENNIS_CORRECT_SCORE_5"] {
      @include headerBg($pool-CORRECT_SCORE_5-background, 'jpg');
    }
    &[class^="NBA_POINT_MARGIN"], &[class*=" NBA_POINT_MARGIN"] {
      @include headerBg($pool-NBA-background, 'jpg');
    }
    &[class^="NFL_POINT_MARGIN"], &[class*=" NFL_POINT_MARGIN"],
    &[class^="NFL_AVB_6"], &[class*=" NFL_AVB_6"],
    &[class^="NFL_AVB_7"], &[class*=" NFL_AVB_7"],
    &[class^="NFL_AVB_8"], &[class*=" NFL_AVB_8"],
    &[class^="NFL_AVB_9"], &[class*=" NFL_AVB_9"],
    &[class^="NFL_AVB_10"], &[class*=" NFL_AVB_10"] {
      @include headerBg($pool-POINT_MARGIN-background, 'jpg');
    }
    &[class^="NFL_POINT_MARGIN"], &[class*=" NFL_POINT_MARGIN"] {
      @include headerBg($pool-POINT_MARGIN-background, 'jpg');
    }
    &[class^="WRC_RACE_WIN"], &[class*=" WRC_RACE_WIN"],
    &[class^="WRC_RACE_ORDER"], &[class*=" WRC_RACE_ORDER"] {
      @include headerBg($pool-WRC-background, 'jpg');
    }
    &[class^="HORSE_RACING_RACE_WIN"], &[class*=" HORSE_RACING_RACE_WIN"] {
      @include headerBg($pool-HORSE_RACING-WIN-background, 'jpg');
    }
    &[class^="HORSE_RACING_RACE_ORDER"], &[class*=" HORSE_RACING_RACE_ORDER"] {
      @include headerBg($pool-HORSE_RACING-ORDER-background, 'jpg');
    }
    &[class^="HORSE_RACING_RACE_PLACE"], &[class*=" HORSE_RACING_RACE_PLACE"] {
      @include headerBg($pool-HORSE_RACING-PLACE-background, 'jpg');
    }
    &[class^="FANTASY_NFL_MVP_2"], &[class*=" FANTASY_NFL_MVP_2"],
    &[class^="FANTASY_NFL_MVP_3"], &[class*=" FANTASY_NFL_MVP_3"],
    &[class^="FANTASY_NFL_MVP_4"], &[class*=" FANTASY_NFL_MVP_4"],
    &[class^="FANTASY_NFL_MVP_5"], &[class*=" FANTASY_NFL_MVP_5"],
    &[class^="FANTASY_NFL_MVP_6"], &[class*=" FANTASY_NFL_MVP_6"],
    &[class^="FANTASY_NFL_MVP_7"], &[class*=" FANTASY_NFL_MVP_7"],
    &[class^="FANTASY_NFL_MVP_8"], &[class*=" FANTASY_NFL_MVP_8"] {
      @include headerBg($pool-FANTASY_NFL_MVP-background, 'jpg');
    }

    &[class^="NFL_AVB_2"], &[class*=" NFL_AVB_2"],
    &[class^="NFL_AVB_3"], &[class*=" NFL_AVB_3"],
    &[class^="NFL_AVB_4"], &[class*=" NFL_AVB_4"],
    &[class^="NFL_AVB_5"], &[class*=" NFL_AVB_5"],
    &[class^="NFL_AVB_6"], &[class*=" NFL_AVB_6"],
    &[class^="NFL_AVB_7"], &[class*=" NFL_AVB_7"],
    &[class^="NFL_AVB_8"], &[class*=" NFL_AVB_8"] {
      @include headerBg($pool-FANTASY_NFL_MVP-background, 'jpg');
    }

    &[class^="ICE_HOCKEY_POINT_MARGIN_3"], &[class*=" ICE_HOCKEY_POINT_MARGIN_3"],
    &[class^="ICE_HOCKEY_POINT_MARGIN_4"], &[class*=" ICE_HOCKEY_POINT_MARGIN_4"],
    &[class^="ICE_HOCKEY_POINT_MARGIN_5"], &[class*=" ICE_HOCKEY_POINT_MARGIN_5"],
    &[class^="ICE_HOCKEY_POINT_MARGIN_6"], &[class*=" ICE_HOCKEY_POINT_MARGIN_6"],
    &[class^="ICE_HOCKEY_POINT_MARGIN_7"], &[class*=" ICE_HOCKEY_POINT_MARGIN_7"],
    &[class^="ICE_HOCKEY_POINT_MARGIN_8"], &[class*=" ICE_HOCKEY_POINT_MARGIN_8"] {
      @include headerBg($pool-ICE_HOCKEY-background, 'jpg');
    }

    &[class^="DARTS_CORRECT_SCORE_12_3"], &[class*=" DARTS_CORRECT_SCORE_12_3"],
    &[class^="DARTS_CORRECT_SCORE_14_3"], &[class*=" DARTS_CORRECT_SCORE_14_3"],
    &[class^="DARTS_CORRECT_SCORE_12_4"], &[class*=" DARTS_CORRECT_SCORE_12_4"],
    &[class^="DARTS_CORRECT_SCORE_14_4"], &[class*=" DARTS_CORRECT_SCORE_14_4"]
    &[class^="DARTS_CORRECT_SCORE_12_5"], &[class*=" DARTS_CORRECT_SCORE_12_5"],
    &[class^="DARTS_CORRECT_SCORE_14_5"], &[class*=" DARTS_CORRECT_SCORE_14_5"],
    &[class^="DARTS_CORRECT_SCORE_12_6"], &[class*=" DARTS_CORRECT_SCORE_12_6"],
    &[class^="DARTS_CORRECT_SCORE_14_6"], &[class*=" DARTS_CORRECT_SCORE_14_6"]
    &[class^="DARTS_CORRECT_SCORE_12_7"], &[class*=" DARTS_CORRECT_SCORE_12_7"],
    &[class^="DARTS_CORRECT_SCORE_14_7"], &[class*=" DARTS_CORRECT_SCORE_14_7"],
    &[class^="DARTS_CORRECT_SCORE"], &[class*=" DARTS_CORRECT_SCORE"] {
      @include headerBg($pool-DARTS-background, 'jpg');
    }
    &[class^="GOLF_EVENT_WIN"], &[class*=" GOLF_EVENT_WIN"] {
      @include headerBg($pool-GOLF_STASH-background, 'jpg');
    }
    &[class^="GOLF_EVENT_WIN_2"], &[class*=" GOLF_EVENT_WIN_2"],
    &[class^="GOLF_EVENT_WIN_3"], &[class*=" GOLF_EVENT_WIN_3"] {
      @include headerBg($pool-GOLF-background, 'jpg');
    }
    &[class^="GREYHOUNDS_RACE_WIN"], &[class*=" GREYHOUNDS_RACE_WIN"] {
      @include headerBg($pool-GREYHOUNDS-background, 'jpg');
    }
    &[class^="GREYHOUNDS_RACE_ORDER"], &[class*=" GREYHOUNDS_RACE_ORDER"] {
      @include headerBg($pool-GREYHOUNDS-background, 'jpg');
    }
    &[class^="GREYHOUNDS_RACE_PLACE"], &[class*=" GREYHOUNDS_RACE_PLACE"] {
      @include headerBg($pool-GREYHOUNDS-background, 'jpg');
    }
    &[class^="ESPORTS_HA"], &[class*="ESPORTS_HA"] {
      @include headerBg($pool-ESPORTS-HA-background, 'jpg');
    }
    &[class^="ESPORTS_HDA"], &[class*="ESPORTS_HDA"] {
      @include headerBg($pool-ESPORTS-HDA-background, 'jpg');
    }
    &[class^="ICE_HOCKEY_HDA"], &[class*="ICE_HOCKEY_HDA"] {
      @include headerBg($pool-ICE_HOCKEY_HDA-background, 'jpg');
    }

    &[class^="HORSE_RACING_RACE_WIN"], &[class*=" HORSE_RACING_RACE_WIN"],
    &[class^="HORSE_RACING_RACE_ORDER"], &[class*=" HORSE_RACING_RACE_ORDER"],
    &[class^="HORSE_RACING_RACE_PLACE"], &[class*=" HORSE_RACING_RACE_PLACE"] {
      &.SWEDISH_RACING {
        @include headerBg($pool-TROTTING-background, 'jpg');
      }
    }

    &[class^="FOOTBALL_MATCH"], &[class*=" FOOTBALL_MATCH"] {
      @include headerBg($pool-FOOTBALL_MATCH-background, 'jpg');
    }

    &[class^="CRICKET_MATCH"], &[class*=" CRICKET_MATCH"] {
      @include headerBg($pool-CRICKET-background, 'jpg');
    }

    .match-pool-image {
      position: absolute;
      display: flex;
      flex: 0 0 50%;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      bottom: 0;
      right: 0;
      overflow: hidden;
      @apply z-0;
      img {
        min-height: 204px;
        height: 100%;
        @media (max-width : 767px) {
          min-height: 170px;
          height: 100%;
        }
      }
      img[src$='.jpg'], img[src$='.png'] {
        object-fit: contain;
        @media (max-width: 767px) {
          filter: brightness(62%);
        }
      }
    }


    .pool-text {
      @apply z-10;
      @apply mb-custom-13;
      @apply text-29 leading-30 font-semibold font-accent text-pool-header-color;
      letter-spacing: -0.3px;
      .lessen {
        @apply text-pool-header-lessen-color;
      }
      .prize-highlight {
        @apply text-boosted-color;
        &.boosted {
          @apply text-boosted-color;
        }
        &.bdg {
          @apply text-bdg-color;
        }
        &.rollover {
          @apply text-rollover-color;
        }
      }
      @media screen and (max-width: 479px) {
        @apply mb-custom-11 text-27 leading-27;
      }
    }

    .pool-start-time {
      @apply text-13 text-pool-header-color z-10;
    }

    .pool-action-btns {
      @apply flex flex-row mt-custom-18 justify-between items-center;
      width: 370px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      @media screen and (max-width: 479px){
        width: 100%;
        @apply mt-custom-11;
      }
    }
  }

  &.freeplay {
    @apply pt-custom-24 bg-no-repeat bg-freeplay-header-bg;
    min-height: 204px;
    background-size: 40%;
    background-position: 100% 100%;
    background-image: url($freeplay-banner-bg-image);

    .pool-text {
      &.fp-banner {
        @apply font-semibold mb-0 text-freeplay-header-color;
        font-size: 32px;
        line-height: 37px;
      }
    }
  }

  &.leaderboard {
    @apply justify-between border-0 relative overflow-hidden;
    @apply pt-custom-24 bg-no-repeat bg-freeplay-header-bg;
    min-height: 204px;
    height: 100%;
    max-height: 204px;

    .pool-text {
      @apply mb-custom-13 text-29 leading-30;
      @apply font-semibold font-accent text-pool-header-color;
      letter-spacing: -0.3px;

      @media screen and (max-width: 479px) {
        @apply mb-custom-11 text-27 leading-27;
      }
    }

    .pool-start-time {
      @apply text-13 text-pool-header-color;
    }

    .masked-icon {
      @apply absolute text-link-color opacity-30;
      font-size: 200px;
      right: 0;
      bottom: -20px;
    }
  }
}
// Header
.pool-header {
  &.pool-header {
    .pool-banner.headline {
      min-height: 206px;
    }
  }
  &.ticket {
    @apply mb-0;
  }
}
// Back block
.back-block {
  @apply flex flex-row pr-custom-16 pl-custom-16 justify-between items-center;
  @apply bg-container-bg text-container-color;
  height: 58px;
  &.border-bottom {
    @apply border-b border-global-border justify-between;
  }
  .back-section {
    @apply flex flex-row items-center;
    width: 100px;
  }
  .return {
    @apply inline-flex flex-row justify-start items-center font-semibold cursor-pointer;
    .icon {
      @apply text-17 mr-custom-6 text-return-arrow-color;
    }
  }
}
