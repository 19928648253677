.lib-pool-summary {
  .mobile-view & {
    display: block !important;
  }
}

.content.racing-summary {
  min-height: 100px;
  @apply px-custom-16 pb-custom-16 pt-custom-24;
}

.racing-summary-event {
  @apply mb-custom-30;

  .component-header-row {
    @apply flex w-full justify-between items-center mb-custom-16;
    .widget-header {
      @apply text-17 leading-23 font-semibold text-left;
      @apply text-headings-color;
      &.small {
        @apply text-14;
      }
    }
  }

  .racing-competitor {
    @apply flex justify-between items-center;
    @apply py-custom-12 pl-custom-14 pr-custom-16 border-b border-global-border;
    &.preview {
      @apply mb-custom-6 px-0 pb-custom-9;
    }

    .left-content-competitor {
      @apply flex items-center;

      .competitor-numbers {
        @apply flex flex-col items-start mr-custom-16;
        &.summary {
          @apply mr-custom-14;
        }

        .card-number {
          @apply flex justify-center items-center rounded-full mb-custom-5;
          @apply text-13 font-semibold;
          width: 24px;
          height: 24px;
        }
      }

      .silk-odds-container {
        @apply flex items-center;
        img {
          @apply max-w-full inline-block align-middle;
        }
        .race-silk {
          height: 26px;
          z-index: 1;
          @apply mr-custom-16;

          &.preview {
            height: 24px;
            @apply mr-custom-14;
          }
        }

        .fav {
          height: 26px;
          z-index: 1;
          width: 26px;
          @apply flex items-center justify-center mr-custom-16;
          &.preview {
            width: 24px;
            @apply mr-custom-14;
          }
        }
      }

      .competitor-info {
        .horse-name {
          @apply text-16 font-semibold mb-custom-2;
          &.preview {
            @apply text-14 font-normal;
          }
        }
      }
    }

    .racing-preview-close {
      width: 16px;
      @apply cursor-pointer text-reduced-color;
    }
  }
}
