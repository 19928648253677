.cs-coupon {
  @apply p-custom-16;

  .cs-item {
    @apply mb-custom-24;

    .cs-competitors {
      @apply flex flex-row mb-custom-8 justify-between items-center;
      .cs-team {
        @apply w-1/3 leading-19 font-medium;
        @media screen and (max-width: 479px) {
          width: 40%;
        }
        &.away {
          @apply text-right;
        }
      }
      .cs-date {
        @apply text-reduced-color text-12 text-center mx-auto;
        width: 30%;
      }
    }

    .cs-legs {
      @apply flex flex-row justify-between items-center;

      .cs-legs-selections {
        @apply flex justify-between flex-wrap relative;
        width: 28%;
        order: 0;

        @media screen and (max-width: 991px) {
          width: 31%;
        }

        @media screen and (max-width: 479px) {
          width: 31%;
        }

        &.draw-scores {
          @apply justify-center;
          width: 20%;
          @media screen and (max-width: 991px) {
            @apply h-full flex-col flex-wrap items-center content-center;
            width: 21%;
          }

          @media screen and (max-width: 479px) {
            width: 31%;
          }
        }

        &.point-margin {
          width: 40%;
        }
      }
    }
  }
}
