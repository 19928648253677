.football-stats {
  @apply pb-custom-10 border-b border-global-border;
  &.no-bottom-border {
    @apply border-none;
  }
  //
  //.coupon-btn.result.LOSS {
  //  background-color: var(--selection-lost-color);
  //  color: var(--selection-win-color);
  //}
}
