// Color of overlay background
$color-overlay: rgba(0, 0, 0, .5) !default;

// Position of dialog
$dialog-position-top: 25% !default;

// Transition time
// !! The same as the hideDelay variable defined in ngx-smart-modal.component.ts
$transition-duration: 200ms !default;

// Transition effect
// linear | ease | ease-in | ease-out | ease-in-out
$transition-timing-function: ease-in-out !default;

// Body if modal is opened
body.dialog-open {
  overflow: hidden;
}

// Close button in modal
.cb-dialog-btn-close {
  border: 0;
  background: none;
  color: var(--container-color);
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

// Overlay
.overlay {
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100%;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color $transition-duration;
  background-color: transparent;
  z-index: 999;

  &.cb-overlay-open {
    background-color: $color-overlay;
  }

  &.transparent {
    background-color: transparent;
  }
}

// Dialog modal
.cb-dialog {
  position: relative;
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  pointer-events: none;
  // top: 9%;

  // When dialog is closing
  &.cb-dialog-close {
    opacity: 0;
  }

  &.cb-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
  }
}

.cb-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: transparent;
  border-radius: 2px;
  padding: 1rem;
  margin-top: $dialog-position-top;
  //box-shadow: $merchant-box-shadow;
  outline: 0;

  // For performance purpose
  transform: translate3d(0, 0, 0);
}

.cb-body {
  position: relative;
  flex: 1 1 auto;
}

/* *************************
* Animations
* *************************/

.cb-dialog[class*=cb-dialog-animation-] {
  will-change: transform;
  transition: transform $transition-duration $transition-timing-function, opacity $transition-duration;
}

// Left to right (ltr)
.cb-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0);

  &.cb-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.cb-dialog-close {
    transform: translate3d(-50%, 0, 0);
  }
}

// Right to left (ltr)
.cb-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0);

  &.cb-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.cb-dialog-close {
    transform: translate3d(50%, 0, 0);
  }
}

// Top to bottom (ttb)
.cb-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0);

  &.cb-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.cb-dialog-close {
    transform: translate3d(0, -50%, 0);
  }
}

// Bottom to top (btt)
.cb-dialog-animation-btt {
  transform: translate3d(0, 50%, 0);

  &.cb-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.cb-dialog-close {
    transform: translate3d(0, 50%, 0);
  }
}
