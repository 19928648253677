.sports-pool-leg.hda {
  &.ghost {
    min-height: 0;
    @apply justify-end bg-highlight-bg text-highlight-color;
    .left-leg-info.ghost {
      @apply flex opacity-0;
      &._1x2 {
        flex: 0 auto;
      }
      &.auto-w {
        @apply w-auto;
        @media screen and (max-width: 479px) {
          @apply w-full;
        }
      }
    }
    .right-btn-holder {
      .coupon-btn.ghost {
        @apply bg-transparent text-13;
        @apply text-highlight-color;
        height: 30px;
      }
    }
  }
}
