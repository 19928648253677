.component-header-row.ticket-selections {
  @apply mb-0 py-custom-24 px-custom-16;
  @apply border-b border-global-border;
  .widget-header {
    @apply text-17 leading-23 font-semibold text-left;
    &.racing {
      @apply flex flex-row items-center;
      .extract {
        @apply ml-auto;
        .link {
          @apply text-12 text-link-color underline cursor-pointer;
        }
      }
    }
  }
}

.preview-leg {
  @apply flex border-b border-global-border;
  &.abandoned {
    // @apply opacity-50;
    .preview-leg-details {
      .preview-details-flip {
        .preview-start-time {
          /*span:last-child, */strong:last-child {
            text-transform: uppercase;
            color: var(--container-bg);
            background: var(--text-color);
            border-radius: 11px;
            padding: 4px 6px;
            font-weight: bold;
            width: fit-content;
          }
        }
      }
    }
  }
  /* left side */
  .preview-leg-details {
    @apply flex flex-col justify-start items-stretch px-custom-8 py-custom-10;
    @apply bg-highlight-bg text-highlight-color;
    width: 100px;
    min-width: 124px;

    .preview-details-flip {
      @apply text-13 leading-17 text-reduced-color;
      .distribution-leg {
        @apply flex justify-between items-start w-full;
        height: 38px;

        .preview-leg-x {
          @apply text-headings-color text-14 leading-17 font-semibold;
        }

        .preview-distribution {
          @apply flex justify-center items-center;
          @apply bg-container-bg text-inplay-bg shadow-btn-shadow cursor-pointer;
          width: 20px;
          height: 20px;
          min-width: 20px; // force width for when not enought space
          border-radius: 2px;
          @media all and (max-width: 479px) {
            @apply mr-custom-8;
          }
        }

        .race-track {
          @apply text-12;
        }
      }

      .preview-start-time {
        @apply mt-custom-8 text-12;
        &.in-progress {
          @apply text-inplay-bg;
        }

        // OR !! if it's the only span maybe you can put span last child or smth and use the .abandoned class below the preview-leg
        // .abandoned-pill {
        //   text-transform: uppercase;
        //   color: var(--container-bg);
        //   background: var(--text-color);
        //   border-radius: 11px;
        //   padding: 4px 6px;
        //   font-weight: bold;
        // }
      }
    }
  }

  /* right side */
  .leg-fixture-results {
    @apply relative flex flex-col w-full justify-center items-center;
    .leg-container {
      @apply flex h-full mr-auto ml-auto pt-custom-16 pb-custom-16 justify-between flex-wrap;
      width: 92%;

      .fixture-teams {
        @apply mb-custom-6 font-normal;
      }

      .end-to-end-fixture-2 {
        @apply flex w-full justify-between;

        .preview- {
          @apply relative text-center text-12;
          width: 30%;
          height: 20px;
          line-height: 1.2;
          &.hockey {
            @apply w-1/5;
          }
        }
      }

      .leg-selections {
        @apply flex flex-wrap justify-between w-full;
      }

      .preview-horse-selection {
        @apply relative flex w-full mb-custom-14 py-custom-8 px-custom-10 items-center;
        @apply border border-transparent rounded-container;
        max-width: 49%;
        min-height: 50px;
        &.results- {
          @apply bg-selection-bg text-selection-color;
          min-width: 49%;

          &.order_withdrawn {
            opacity: 0.6;
          }

          .silk-number-result {
            @apply flex items-center;
            min-width: 70px;
            &.dogs {
              min-width: 30px;
            }
          }

          .fav-icon {
            min-width: 30px;
            text-align: center;
          }

          .racing-result {
            @apply flex w-full mr-custom-12 justify-center items-center;
            @apply border border-transparent rounded-none text-12 font-semibold;
            height: 30px;
            &.won {
              @apply bg-selection-win-bg text-selection-win-color;
              @apply border border-selection-win-extra relative;
              cursor: default;
              &:after {
                @apply absolute top-0 left-0 w-0 h-0;
                content: "";
                border-top-width: 10px;
                border-top-style: solid;
                border-top-color: var(--selection-win-extra);
                border-right: 10px solid transparent;
              }
            }

            &.lost {
              @apply bg-selection-lost-bg text-selection-lost-color;
              @apply border border-selection-lost-extra relative;
              cursor: default;
              &:after {
                @apply absolute bottom-0 right-0 w-0 h-0;
                content: "";
                border-bottom-width: 10px;
                border-bottom-style: solid;
                border-bottom-color: var(--selection-lost-extra);
                border-left: 10px solid transparent;
              }
            }

            &.non-runner {
              @apply bg-selection-draw-bg text-selection-draw-color;
            }
          }

          .card-silk {
            @apply flex items-center;

            .racecard-number {
              @apply flex justify-center items-center border-2 text-12 font-semibold mr-custom-8;
              @apply bg-container-bg border-global-border;
              width: 22px;
              height: 22px;
              border-radius: 20px;
            }

            .race-silk-2 {
              height: 20px;
              z-index: 1;
              margin-right: 0;
            }
          }

          .horse-preview-name {
            @apply font-medium;
            word-break: break-word;

            &.multi-name {
              @apply leading-1;
            }
          }
        }

        &.in-progress {
          @apply border-inplay-bg;
        }
      }

      .preview-selections {
        @apply flex w-full flex-wrap;
      }

      .preview-selections-2 {
        @apply relative w-full flex flex-wrap;
        margin-top: 16px;
        margin-bottom: -8px;
        &.l-r {
          @apply relative justify-between;
        }

        .prev-left {
          @apply flex justify-start;
          &.hockey {
            @apply w-2/5;
          }

          .coupon-btn.cs.preview {
            cursor: default;
            @apply w-auto mr-custom-10 ml-0;
          }
        }

        .prev-center {
          @apply flex justify-center;
          &.hockey {
            @apply w-1/5;
          }

          .coupon-btn.cs.preview {
            cursor: default;
            @apply w-auto ml-custom-5 mr-custom-5;
          }
        }

        .prev-right {
          @apply flex justify-end;
          &.hockey {
            @apply w-2/5;
          }

          .coupon-btn.cs.preview {
            cursor: default;
            @apply w-auto ml-custom-10 mr-0;
          }
        }
      }

      .ranking-arrow {
        width: 20px;
        height: 17px;
        @apply inline-flex items-center justify-center;
        @apply bg-pill-gray-bg text-pill-gray-color rounded-container ml-custom-4 text-center;
      }

      .place-ranking {
        @apply flex w-full p-custom-16 justify-between items-center rounded-container;
        @apply bg-highlight-bg mt-custom-12 mx-auto;
      }
    }
  }

  /* modifiers */

  &.live {
    @apply relative;
    // Should not use border color
    &:before {
      @apply absolute top-0 left-0 h-full bg-inplay-bg;
      content: '';
      width: 2px;
    }

    // TODO: Check cause it's not correct. Should be applied only on left
  }

  @media screen and (max-width: 991px) {
    @apply pt-0 flex-col;
    .preview-leg-details {
      @apply w-auto pr-0 pl-0 flex-row justify-between items-center;
      @apply border-r-0 border-b border-global-border;
      height: 54px;
      margin-left: -1px;

      .preview-details-flip {
        @apply flex mr-auto ml-auto mb-0 items-center;
        width: 95%;

        .distribution-leg {
          @apply mb-0 flex-row-reverse justify-end;
          .preview-leg-x {
            @apply pr-custom-10 mr-custom-10 text-14 text-headings-color;
          }
        }

        .preview-start-time {
          @apply mt-0 whitespace-nowrap;
          @media all and (max-width: 479px) {
            @apply mt-0;
          }
        }
      }
    }

    .leg-fixture-results {
      .leg-container {
        @apply pt-0 pb-0;

        .preview-selections {
          @apply pb-0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    @apply pb-custom-8;
    .preview-leg-details {
      @apply mb-custom-8 pt-custom-5 pb-custom-5 border-b-0;

      .preview-details-flip {
        @apply text-14;

        .distribution-leg {

          .preview-leg-x {
            @apply pr-custom-10 mr-0 mb-0 text-14 text-headings-color;
          }
        }
      }
    }
    .leg-fixture-results {
      .leg-container {
        .preview-selections {
          margin-bottom: -6px;
        }
      }
    }
  }
}
