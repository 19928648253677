
.page {
  @apply relative w-full h-full flex flex-row items-start justify-center;
  padding: 28px 24px 198px;
  @media screen and (max-width: 991px) {
    @apply pr-0 pl-0;
    padding-top: 28px;
  }

  .page-content {
    @apply flex flex-row items-start flex-auto;
    flex: 0 auto;
    min-height: 700px;
    @media only screen and (max-width: 1199px) {
      @apply ml-0 w-full justify-center block;
    }
    @media only screen and (max-width: 767px) {
      @apply block;
    }
  }
}

.desktop-fixed-nav {
  @apply w-left-sidebar sticky;
  top: 28px; // this might need to be added as variable
  // TODO: adjust based on <wallet-notifications> component. Currently a general class is added in wallet-general.scss by the component
}

.main {
  //@apply w-main flex pr-custom-24;
  @apply w-main flex pr-0;
  @media only screen and (max-width: 1200px) {
    @apply max-w-main-content w-full m-auto;
  }
  @media only screen and (max-width: 991px) {
    @apply w-full max-w-main-content pr-custom-24;
  }
  @media only screen and (max-width: 767px) {
    @apply pr-0;
  }

  &.col {
    @media only screen and (max-width: 1200px) {
      @apply max-w-main-content w-full m-auto;
    }
    @media only screen and (max-width: 991px) {
      @apply w-full max-w-main-content pr-custom-24;
    }
    @media only screen and (max-width: 767px) {
      @apply flex flex-col pr-0;
      &.reverse {
        @apply flex-col-reverse;
      }
    }
  }
}

.center {
  @apply w-full max-w-main__center pr-custom-24;
  @media only screen and (max-width: 1200px) {
    @apply w-full max-w-full;
  }
  @media only screen and (max-width: 991px) {
    @apply max-w-full pr-custom-24 pl-custom-24;
  }
  @media only screen and (max-width: 767px) {
    @apply pr-0 pl-0;
  }
  //@media only screen and (min-width: 767px) and (max-width: 991px) {
  //  width: calc(100% - 300px);
  //}
}

.right {
  @apply w-main__right;
  @media only screen and (max-width: 1200px) {
    @apply w-1/4 max-w-full;
    min-width: 300px !important;
  }
  @media only screen and (max-width: 991px) {
    @apply hidden;
  }

  &.no-hide {
    @apply w-full;
    display: block !important;

    @media only screen and (min-width: 767px) {
      @apply w-1/4 max-w-full;
      min-width: 300px !important;
    }
  }
}

.full {
  @apply max-w-main-content w-full m-auto;
  @media only screen and (max-width: 1200px) {
    @apply w-full max-w-full block;
  }
}

.cb-bold {
  @apply font-bold;
}

//

// pool type group
.segmented-control {
  @apply flex flex-row w-full p-custom-8 rounded-container justify-start items-center;
  @apply bg-container-bg;
  @apply border-b border-global-border;
  overflow-x: auto;
  @media all and (max-width: 767px) {
    @apply mb-custom-12;
  }
  @media all and (max-width: 479px) {
    @apply py-custom-12 pl-custom-12;
  }

  &.stats-heading {
    @apply justify-between bg-highlight-bg text-highlight-color rounded-b-none;
  }
  &.profile {
    @media all and (max-width: 479px) {
      @apply mb-custom-12;
    }
  }
}

.content {
  @apply shadow-content-shadow dark:shadow-dark-content-shadow rounded-container border-0 mb-custom-24;
  @apply bg-container-bg text-container-color;
  @media only screen and (max-width: 991px) {
    @apply w-full;
  }
  &.pool-group {
    @apply w-full p-0 mb-custom-24;
  }

  &.segmented-control {
    @apply pt-custom-12 pb-custom-12;
  }

  &.stats {
    @apply mt-custom-6 mx-custom-16 mb-custom-16;
    @apply mx-0;
    //.full-w & {
    //  @apply mx-0;
    //}
  }

  &.widget {
    @apply flex flex-col items-start pr-custom-16 pl-custom-16 pt-custom-24 pb-custom-18;

    .component-header-row {
      @apply flex flex-row items-center justify-between w-full mb-custom-16;
      .widget-header {
        @apply text-17 leading-23 font-semibold text-left;
        @apply text-headings-color;
      }
    }

    &.no-padding {
      @apply p-0;
      .component-header-row {
        @apply pr-custom-16 pl-custom-16 pt-custom-24;
      }
    }

    &.no-margin {
      @apply mb-0;
    }
  }

  &.direct-join-selections {
    @apply flex flex-col items-stretch;
    min-height: 260px;
  }

  &.ticket-info {
    @apply rounded-t-none;
  }
}
// help iframes

iframe#terms_docs, iframe#faq_docs{
  border: 0;
  width: 100%;
  height: 100vh;
  max-height: 1000px;
}

// common elements across components

.widget-header {
  @apply text-17 leading-23 font-semibold text-left;
  @apply text-headings-color;
}

// custom scrollbar
@if $custom-scrollbar == true {
  #appsbody::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: var(--scrollbar-drag-bg);
  }
  #appsbody::-webkit-scrollbar {
    width: 4px;
    background-color: #212023;
  }
  #appsbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 0px;
    background-color: var(--scrollbar-bg);
  }

  .segmented-control::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: var(--scrollbar-drag-bg);
  }
  .segmented-control::-webkit-scrollbar {
    height: 8px;
    background-color: var(--scrollbar-bg);
  }
  .segmented-control::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 0px;
    background-color: var(--scrollbar-bg);
  }
}

