$pool-coupon-sep-expansion-body-border-color: #CCC;

$pool-coupon-sep-legno-bg: #110130;
$pool-coupon-sep-legno-colour: #FFF;

$pool-coupon-sep-selected-status-default-bg: #CCC;
$pool-coupon-sep-selected-status-default-color: #353535;
$pool-coupon-sep-selected-status-active-bg: #13ce66;
$pool-coupon-sep-selected-status-active-color: #FFF;

//@import "leg-types/correct-score";
//@import "leg-types/match-result";
//@import "leg-types/btts";
//@import "leg-types/over-under";
//@import "leg-types/ht-ft";
//@import "leg-types/total-goals";
//@import "leg-types/goal-fh";
//@import "leg-types/penalty-taken";
//@import "leg-types/red-cards";
//@import "leg-types/red-or-penalty";
//@import "leg-types/var-overturn";
//@import "leg-types/time-first-goal";
//
//@import "leg-types/corners-default";
//@import "leg-types/corners-ou";
//@import "leg-types/corners-periods";
//@import "leg-types/corners-total";
//@import "leg-types/corners-total-ou";
//
//@import "leg-types/bp-periods";
//@import "leg-types/bp-total";
//@import "leg-types/cards-default";
//@import "leg-types/cards-periods";
//@import "leg-types/cards-total-ou";

.mp-header {
  @apply flex font-medium p-custom-16;
}

.content.mp-header {
  @apply flex flex-row justify-start items-center py-custom-12;
  @apply bg-info-bg text-container-color border-l-3 border-info-border;
  .group-icon {
    @apply mr-custom-16;
    width: 28px;
  }
  .mp-market-header {
    @apply text-17 font-semibold;
  }

  &.results- {
    @apply mt-0 mb-custom-2 rounded-none;
  }
}

.content.mp-leg {
  @apply border-b border-global-border;

  .mat-expansion-panel-header {
    @apply flex justify-between items-center font-medium pl-custom-16 pr-custom-16;
    min-height: 58px;
    .mp-header {
      @apply p-0;
    }

    .mat-expansion-indicator::after {
      padding: 4px;
      @apply text-sep-badge-color;
    }

    &.mat-expanded {
      @apply border-b border-global-border rounded-none;
    }

    .mp-header-left {
      @apply flex items-center;

      .map-leg-badge {
        @apply flex mr-custom-10 justify-center items-center rounded-full;
        @apply bg-sep-badge-bg text-sep-badge-color text-12;
        width: 24px;
        height: 24px;
      }

      .title {
        @apply text-sep-badge-color;
      }

      .mp-leg-check {
        @apply flex mr-custom-10 justify-center items-center;
        @apply bg-pill-gray-bg text-pill-gray-color;
        border-radius: 20px;
        width: 24px;
        height: 25px;

        &.active {
          @apply bg-selection-win-bg text-selection-win-color;
        }
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .mp-blocks {
    @apply p-custom-16;
    .cs-coupon {
      @apply p-0;
      .cs-item {
        @apply mb-0;
      }
    }
    .mp-bttns {
      @apply flex mt-custom-12 justify-between flex-wrap;

      .coupon-btn.disabled {
        opacity: 0.3;
        &.active {
          @apply bg-selection-active-bg text-selection-active-color;
        }
      }

      //.vote-count {
      //  @apply leading-1 mx-custom-4 w-full;
      //}
    }
  }
}

#pool_coupon_spa {
  .mat-expansion-panel-header {
    @apply bg-container-bg text-container-color;
  }
  .mat-expansion-panel-content {
    @apply bg-container-bg text-container-color;
  }
}
