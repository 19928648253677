.content.widget.winning-information {
  @apply pb-0 items-stretch bg-container-bg text-container-color;
}
// we extract to re-use on ticket breakdown

.prize-table-item {
  @apply text-reduced-color mr-neg-custom-16 ml-neg-custom-16;
  &.no-margin {
    @apply mr-0 ml-0;
  }
}

.prize-cols {
  @apply flex flex-row justify-center items-stretch border-b border-global-border;
  height: 50px;
  &.heading-row {
    @apply border-t;
  }
  .prize-table {
    @apply flex justify-center items-center;
    span {
      @apply text-12 leading-16 text-center;
      &.blk {
        @apply font-medium;
      }
    }
    &.b-rl {
      @apply border-l border-r border-global-border;
    }
    &.b-l {
      @apply border-l border-global-border;
    }
  }
  .w-col {
    @apply relative pl-custom-10 pr-custom-10;
    min-height: 1px;
  }

  &._2nd {
    @apply pt-0 pb-0;
    height: 49px;
  }
  &.bottom {
    @apply border-b-0;
  }
}
