// Desktop - left side menu
.desktop-fixed-nav {
  @apply hidden lg:flex flex-col items-end flex-1;
  width: 275px;
  .left-nav {
    max-width: 290px;
    width: 100%;
    padding-right: 80px;
  }
  ul.vertical-nav {
    @apply flex flex-col max-w-vertical_menu mb-0 pr-custom-13 list-none;
    max-width: 240px;
    li {
      @apply flex text-17 items-center space-x-3 rounded-nav_link leading-32 text-15 font-medium;
      @apply bg-v-menu-bg;
      @apply hover:bg-v-menu-hover hover:text-v-menu-hover-text;
      border-radius: 11px;
      height: 56px;
      // margin-left: 8px;
      &.help-menu-item {
        display: $help-button;
      }

      a.drawer-navlink {
        @apply block border-l-3 border-transparent leading-48 text-v-menu-color;
        @apply w-full mt-custom-7 mb-custom-7 pr-custom-12;
        height: 32px;
        transition: all 300ms ease;
        .icon {
          @apply flex items-center;
          margin-right: 15px;
          margin-left: 9px;
          width: 32px;
          height: 32px;
          font-size: 18px;
        }
      }
      .sidebar-nav-link {
        @apply flex mx-custom-4 items-center leading-22 text-17 font-medium no-underline;
        height: 38px;
      }

      &.freeplay {
        @apply relative;
        .fp-badge {
          @apply flex absolute top-1.5 right-0 text-11 text-center items-center justify-center;
          @apply rounded-full bg-freeplay-bg text-freeplay-color;
          width: 20px;
          height: 20px;
          min-width: 20px;
          max-width: 20px;
          min-height: 20px;
          max-height: 20px;
        }
      }

      &.additional-link {
        height: auto;
        a.drawer-navlink {
          height: 34px;
          font-size: 13px;
          margin: 0;
          margin-left: 10px;
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }

      &.submenu {
        height: 14px;
        a.drawer-navlink {
          height: 14px;
          @apply leading-1 pr-0;
        }
        .sidebar-nav-link {
          height: 14px;
          @apply leading-1 text-11;
          @apply justify-start;

          .mini {
            padding: 1px;
            @apply text-11 inline-flex pr-custom-2 justify-center items-center font-semibold w-auto;
            @apply bg-primary text-secondary-color;
          }
        }
      }
    }
    li.active {
      &:after {
        content: '';
        position: absolute;
        @apply border-l-3 rounded-l-none h-9;
        @apply bg-v-menu-active-bg border-v-menu-active-border;
      }
      a.drawer-navlink {
        @apply text-v-menu-active-color;
      }
    }
    li:hover{
      a.drawer-navlink {
        @apply text-v-menu-active-color;
      }
    }
  }
}

// Mobile - top menu
.nav-backdrop {
  @apply flex sticky left-0 top-0 right-0 bottom-auto shadow-nav-backdrop h-nav-backdrop;
}
.mobile-menu-wrapper {
  @apply bg-navbar;
}

#mobile-menu-container {
  @apply flex flex-row w-full max-w-main-content mx-auto justify-around items-stretch h-mobile-menu;
  //@apply border-b border-global-border;
  @apply bg-navbar;
  overflow-x: auto;

  a {
    @apply flex justify-center items-center border-b-3 no-underline text-14;
    @apply bg-h-menu-bg text-h-menu-color border-h-menu-border;
    width: 20%;

    &.help-menu-item {
      display: $help-button;
    }
    @media screen and (max-width: 767px) {
      @apply text-13;
    }
    @media screen and (max-width: 479px) {
      @apply w-auto h-full;
    }

    .mobile-nav-item {
      @apply flex px-custom-12 flex-col justify-center items-center whitespace-nowrap;
      @media screen and (max-width: 991px) {
        @apply pr-custom-24 pl-custom-24;
      }
      @media screen and (max-width: 767px) {
        @apply pr-custom-2 pl-custom-2;
      }
      @media screen and (max-width: 479px) {
        @apply h-full text-13 whitespace-nowrap;
        padding: 11px 10px 8px;
      }

      .mobile-nav-icon {
        @apply text-14 font-normal mb-custom-6 text-center;
        height: 20px; // force the height due the icon font
        @media screen and (max-width: 767px) {
          @apply mb-custom-4;
        }
        @media screen and (max-width: 479px) {
          @apply mb-custom-2;
        }
      }
      .mobile-nav-label {
        // Nothing
        @media screen and (max-width: 479px) {
          @apply text-12;
        }
      }
    }

    &.freeplay {
      @apply relative;
      .fp-badge {
        @apply flex absolute top-1.5 right-0 text-11 text-center items-center justify-center;
        @apply rounded-full bg-freeplay-bg text-freeplay-color;
        width: 20px;
        height: 20px;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
      }
    }

    &.tickets {
      @apply relative;
      .gt-badge {
        @apply flex absolute top-1.5 right-0 text-11 text-center items-center justify-center;
        @apply rounded-full bg-primary text-secondary-color;
        width: 20px;
        height: 20px;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
      }
    }

    &.active {
      @apply font-semibold;
      @apply bg-h-menu-active-bg text-h-menu-active-color border-h-menu-active-border;
    }
  }


  &.fixed {
    position: fixed;
    @apply top-0 left-0 w-full;
    z-index : 999;
  }
}

.divider {
  width: 92%;
  height: 1px;
  display: block;
  position: relative;
  background-color: var(--color-soft-text);
  margin-left: 10px;
  opacity: 0.1;
  margin-bottom: 4px;
  margin-top: 13px;
}

