#lib-cb-video-player {
  @apply fixed shadow-content-shadow dark:shadow-dark-content-shadow;
  bottom: 20px;
  left: 20px;
  width: 100%;
  max-width: 484px;
  height: 272px;
  background: #000;
  z-index: 99999;
  border: 5px solid #FFF;
  @media (max-width: 767px){
    width: 230px;
    height: 120px;
  }
  .header {
    @apply absolute w-full top-0 left-0 hidden;
    z-index: 99;
  }
  &:hover .header {
    @apply flex;
    .drag-handle, .close {
      @apply text-20 cursor-pointer bg-container-bg text-container-color px-custom-8 py-custom-2;
    }
  }

  .iframe-container .video {
    width: 100%;
    height: 262px;
    z-index: 90;
    @media (max-width: 767px){
      height: 110px;
    }
  }
}
