.betslip-container {
  .betslip {
    @apply flex flex-col;
    @apply mb-custom-24;
    @apply pt-custom-24 pr-custom-16 pl-custom-16 pb-custom-24;

    .component-header-row {
      @apply flex w-full justify-between items-center mb-custom-16;
      @apply bg-container-bg;
      .widget-header {
        @apply text-17 leading-23 font-semibold text-left text-headings-color;
      }

      .link-label {
        @apply text-14 text-right text-link-color;
        .link {
          @apply cursor-pointer;
        }
      }
    }

    .betslip-select-container {
      @apply flex justify-between items-center mb-custom-16 pt-custom-16 pb-custom-16 rounded-container;
      @apply border-t border-b border-global-border;

      .half {
        width: 41%;

        &.sp {
          width: 56%;
        }

        &.full {
          width: 100%;
        }

        .select-label {
          @apply mb-custom-3 text-13 text-select-label;
        }

        .new-select {
          @apply flex p-custom-10 justify-between text-15 font-semibold;
          @apply bg-select-bg text-select-color;
          &.sp {
            width: 76%;
            @apply rounded-tr-none rounded-br-none;
          }
        }

        .sp-refresh {
          width: 24%;
          @apply justify-center items-center rounded-tr-container rounded-br-container;
          @apply border border-global-border border-l-0;
          .select-icon-dropdown {
            @apply h-full;
          }

          .smartpick_link {
            @apply flex flex-col w-full h-full justify-center items-center text-15;
            @apply text-link-color;
          }
        }

        .combined {
          @apply flex flex-row;
        }
      }
    }
  }
}


.betslip-syndicate-create {
  @apply mt-custom-24;
  &.mobile-version {
    @apply mt-0;
  }
}
.content.mobile-syndi-create {
  @apply mt-custom-24 px-custom-12 py-custom-16;
}

.syndi-create-detail {
  @apply flex mb-custom-16 pb-custom-6 justify-between;
  @apply border-b border-global-border;
  .syndi-create-value {
    @apply font-semibold;
  }
  &.last {
    @apply mb-0;
  }
}

// Mobile

.mobile-betslip {
  @apply flex flex-col mb-custom-16 px-custom-8 justify-between;

  .action-btns {
    @apply w-full flex justify-between mb-custom-12;
  }
}


// Extras - obsolete
// TODO: Remove next block - when changed everywhere
.betslip-nudge {
  height: 51px;
  @apply flex flex-row w-full justify-center items-center rounded-btn-radius font-semibold text-13 shadow-btn-shadow;
  @apply bg-info-bg text-info-color;
  &.extra {
    @apply h-full p-custom-6;
    min-height: 51px;
  }
  &.mobile {
    @apply rounded-t-btn-radius;
  }

  &.error {
    @apply bg-error-bg text-error-color;
  }

  &.warning {
    @apply bg-warning-bg text-warning-color;
  }
}
