.units {
  @apply flex w-full p-custom-16 justify-between items-center rounded-container;
  @apply bg-ud-bg text-ud-color mt-custom-12 mx-auto;

  &.cs {
    @apply items-start;
  }

  .unit-selections-2 {
    width: 46%;

    &._1x2 {
      width: 31%;
    }

    &.cs {
      width: 31%;
      @apply text-13 leading-19;
      @media screen and (max-width: 479px) {
        @apply text-center;
      }
    }

    &.single {
      @apply w-full;
    }

    &.sep {
      @apply flex justify-between flex-wrap flex-row w-full;
    }
  }

  .unit-item-2 {
    @apply flex justify-between items-center rounded-container p-custom-8;
    @apply bg-container-bg text-container-color;
    &._1x2 {
      @apply mb-0;
    }

    &.cs {
      @apply mb-custom-12;
    }

    &.racing {
      @apply p-custom-12 mb-custom-12;
    }

    &.sep {
      @apply mb-custom-12;
      width: 46%;

      &.last {
        @apply mb-0;
      }
    }

    @media screen and (max-width: 479px) {
      @apply pr-custom-2 pl-custom-2 flex-col;
      &.racing {
        @apply flex-row;
      }
    }

  }

  .racing-content-left {
    @apply flex items-center;

    .win-result {
      width: 40px;
    }

    .racecard {
      @apply mr-custom-8;
      width: 20px;
    }

    .racing-silk-preview {
      @apply mr-custom-14;
      height: 20px;

      &.dogs {
        @apply rounded-container;
      }

      &.favorite-icon {
        @apply text-27 flex flex-col justify-center;
        height: 20px;
      }
    }
  }

  .breakdown-score-2 {
    @apply font-semibold;
  }

  .breakdown-units-2 {
    @apply text-right text-reduced-color;
  }
}
