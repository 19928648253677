.content.ticket-component {
  @apply flex flex-col items-stretch pt-custom-24 px-custom-16 pb-0;
  &.cash-out {
    @apply px-custom-16 border-t-3;
    @apply border-offer-bg;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .component-header-row.cash-out {
    @apply mt-custom-2 flex flex-col items-center text-center;

    .widget-header.cash-out {
      @apply text-23 leading-23 font-bold text-left;
      @apply bg-container-bg text-container-color;
      span {
        @apply text-offer-bg;
      }
    }

    .expiry-text {
      @apply mt-custom-20 px-custom-18 py-custom-6 rounded-container text-13;
      @apply bg-warning-bg text-warning-color;
    }
  }

  .slide-container {
    @apply mt-custom-30;
  }

  .cash-out-btn-container {
    @apply mt-custom-24 mb-custom-14;

    &.confirm {
      @apply py-custom-24 px-custom-16 border-t-3 rounded-container text-center;
      @apply bg-info-bg text-info-color border-info-border;

      .cash-out-confirm {
        @apply mb-custom-14 text-16 font-semibold;
        @apply text-info-color;
      }
    }

    .success {
      @apply w-full flex flex-row justify-center px-custom-4 py-custom-16 mx-auto;
      @apply bg-success-bg text-success-color;
    }

    .error {
      @apply w-full flex flex-row justify-center px-custom-4 py-custom-16 mx-auto;
      @apply bg-error-bg text-error-color;
    }
  }

  .join-card-bottom-row {
    @apply flex flex-row mt-custom-14 mb-custom-4 justify-between;
    .percentage {
      span {
        @apply font-semibold;
      }
    }
  }
}
