.pools-list {
  &.content.segmented-control {
    @apply mt-0 mb-custom-24 rounded-none shadow-none;

    .event-handler {
      @apply absolute w-full h-full block z-10;
      @apply bg-transparent cursor-pointer;
    }
  }
}
