select {
  &.blue {
    // TODO: Need improvements to use css variable color
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  }
}

.pool-type-row .sport-icon {
  color: #FFF;
}


// Layout changes for  full width  microsites

#merchant {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

//.page {
//  padding: 5px 5px 5px;
//}
//.page-content {
//  width: 100%;
//}
//.desktop-fixed-nav {
//  max-width: 275px;
//  top: 5px;
//}
//.main {
//  width: calc(100vw - 290px);
//  padding-right: 0;
//}
//
//.full {
//  margin: 0;
//  max-width: 100%;
//}
//.center {
//  max-width: calc(100% - 330px);
//}
//.syndicate-split {
//  width: 100%;
//  .main {
//    width: calc(100vw - 295px);
//  }
//}
//.content.syndicate-nav-bar {
//  max-width: 100%;
//}
//.mobile-menu-wrapper {
//  margin-bottom: 10px;
//}
// MQ
//@media only screen and (min-width: 767px) {
//  .right.no-hide {
//    width: 330px;
//  }
//}
//@media only screen and (max-width: 991px) {
//  .main {
//    width: 100%;
//  }
//  .page {
//    padding: 0;
//  }
//  .center {
//    width: 100%;
//    max-width: 100%;
//  }
//  .main.col {
//    padding-right: 0;
//  }
//  .syndicate-split {
//    width: 100%;
//    margin: 0 auto;
//    .main {
//      width: 100%;
//      .center {
//        padding: 0;
//      }
//    }
//    .full {
//      .main.col.reverse {
//        padding-right: 0;
//        .center {
//          padding-right: 24px;
//        }
//      }
//    }
//  }
//}
//@media only screen and (max-width: 767px){
//  .syndicate-split .full .main.col.reverse .center {
//    padding-right: 0;
//  }
//}

.content.mp-leg .mat-expansion-panel-header .mp-header-left .map-leg-badge {
  color: #FFF;
}

.contribute-content .manual-contribution .contribute-input.left {
  input {
    background-color: #FFF;
  }
}

.w-tab-menu .tab-link .new-feature-badge {
  color: #FFF;
  background-color: #ef3f49;
  border-color: #FFF;
}

.pool-group-ticket {
  .content.no-margin {
    box-shadow: none !important;
  }
}

.pool-group-ticket .group-ticket-invite .search-followers .custom-text-box {
  background-color: #FFF !important;
}

.have-voted .big {
  color: var(--primary-color);
}

.headline.pool-banner {
  .pool-text {
    color: #000 !important;
  }
  sport-sub-code {
    color: #000 !important;
    background: #f5f5f5;
    border-radius: 2px;
    padding: 2px;
  }
}
