@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

$assets-path: '//d20xk4sxen1bs2.cloudfront.net';

$syndicate-captain-profile-image-default: '#{$assets-path}/images/useravatars/default.jpg';
$horse-racing-silk-default: '#{$assets-path}/assets/images/horse_racing/silks/default.png';
$golf-silk-default: '#{$assets-path}/assets/images/golf/silks/default.png';

// $db-bdg-promo-bg-image: '//d2dj9j2vwcb74a.cloudfront.net/assets/images/dynamic-homepage/bdg-promotions.png';
$db-bdg-promo-bg-image: 'https://uploads-ssl.webflow.com/60f7de81c3c490b2dc257bc8/60f7de81c3c4903b27257d46_euro-card-img2.png';
$first-time-users-bg-image: 'https://uploads-ssl.webflow.com/60f7de81c3c490b2dc257bc8/60f7f54f96ca46320184613c_car-1.png';

$pools-no-content-bg-image: '#{$assets-path}/assets/images/helpers/no-content-bg.png';
$syndicate-rebate-banner-image: '#{$assets-path}/assets/images/helpers/syndicate-help-banner.png';

$freeplay-banner-bg-image: '#{$assets-path}/assets/svg-icons/freeplay.svg';
$syndicate-leaderboard-bg-image: '#{$assets-path}/assets/svg-icons/leaderboard.svg';

$custom-scrollbar: true;
$theme: 'light';
$custom-images: 'paf';

// To be moved in a global scss variable file

$pool-CORRECT_SCORE-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/soccer';
$pool-HDA-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/soccer';
$pool-OVER_UNDER-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/soccer';
$pool-BTTS-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/soccer';
$pool-ASIAN_HANDICAP-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/soccer';
$pool-H2H-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-CORRECT_SCORE_3-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-CORRECT_SCORE_5-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-POINT_MARGIN-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/nfl';
$pool-WRC-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-HORSE_RACING-ORDER-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/horses';
$pool-HORSE_RACING-PLACE-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/horses';
$pool-HORSE_RACING-WIN-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/horses';
$pool-HORSE_RACING-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/horses';
$pool-NBA-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/nba';
$pool-FANTASY_NFL_MVP-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/am-football';
$pool-ICE_HOCKEY-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/nhl';
$pool-ICE_HOCKEY_HDA-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/nhl';
$pool-GREYHOUNDS-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-DARTS-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-GOLF-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-GOLF_STASH-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-ESPORTS-HA-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-ESPORTS-HDA-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-TROTTING-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/general';
$pool-FOOTBALL_MATCH-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/soccer';
$pool-CRICKET-background: '#{$assets-path}/assets/images/pool_header_images/#{$custom-images}/cricket';




$help-button: 'flex'; // help button display  in menu. can be: none OR flex

@import "styles/themes";


:root {
  --app-max-width: 1175px;
  @apply light;

  --blue-500: #21a0ff; // present in .light as --accent-color: #2197ff
  --primary: #e97601; // present in .light as --primary-color: #e97601;
  --secondary: #2197ff;
  --secondary-color: #FFFFFF;
  --tertiary-color: #525661;

  // present
  --container-bg: #FFF;
}

@import "styles/var-overrides";
// main imports
@import "cb-ui/component-styles/mixins";
@import "cb-ui/component-styles/modifiers";
@import "cb-ui/component-styles/buttons";
@import "cb-ui/component-styles/layout";
@import "cb-ui/component-styles/libs/video-player";
@import "cb-ui/component-styles/libs/notifications";
@import "cb-ui/component-styles/nav";
@import "cb-ui/component-styles/pool-header";
@import "cb-ui/component-styles/pools-menu";
@import "cb-ui/component-styles/w-tab-menu";
@import "cb-ui/component-styles/w-dropdown";
@import "cb-ui/component-styles/submenu";
@import "cb-ui/component-styles/widgets";
@import "cb-ui/component-styles/offer-slider";
@import "cb-ui/component-styles/libs/croppie";
@import "cb-ui/component-styles/libs/modal";
@import "cb-ui/component-styles/freeplay";
//@import "cb-ui/component-styles/homepage";
@import "cb-ui/component-styles/pool-betslip";
@import "cb-ui/component-styles/pool-winning-information";
@import "cb-ui/component-styles/pool-history";
@import "cb-ui/component-styles/unit-distribution";
@import "cb-ui/component-styles/ticket-selections";
@import "cb-ui/component-styles/offer";
@import "cb-ui/component-styles/tickets";
@import "cb-ui/component-styles/coupon/coupon";
@import "cb-ui/component-styles/coupon/summary";
@import "cb-ui/component-styles/coupon/stats";
@import "cb-ui/component-styles/coupon/grayhounds"; // Gray !!! HAHAHAHAHA Moron me
@import "cb-ui/component-styles/coupon/hda";
@import "cb-ui/component-styles/coupon/cs";
@import "cb-ui/component-styles/coupon/sep/sep";
@import "cb-ui/component-styles/pool-confirm";
@import "cb-ui/component-styles/syndicates";
@import "cb-ui/component-styles/commentary";
@import "cb-ui/component-styles/group-tickets";
// end main imports

// Compoenents used in wallet
// Libs
@import "plyr/src/sass/plyr";
@import "plyr/src/sass/base";

//@import "cb-ui/styles/assets/mixins";
@import "cb-ui/component-styles/colossus-font"; // still needed for some sports icons - needs to be fully replaced

body {
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.1px;
  @apply bg-app-bg font-default text-14;
}

@import "styles/overrides";
