.pool-group-ticket {
  @apply flex flex-col justify-between;
  @apply mb-custom-8;
  @apply pt-custom-16 pr-custom-16 pl-custom-16 pb-custom-24;

  .content.no-margin {
    @apply mb-0;
  }

  .group-ticket-invite {
    @apply flex flex-col w-full mb-custom-8;

    .search-followers {
      @apply flex flex-row w-full justify-between items-center;

      .custom-text-box {
        border-style: solid !important;
        border-width: 1px !important;
        border-color: var(--global-border-reduced) !important;
        background-color: var(--container-reduced-color) !important;
        height: 32px;
      }
    }

    .followers-list {
      @apply flex flex-col w-full h-auto overflow-auto mt-custom-16;
      max-height: 200px;

      .loading {
        @apply flex justify-center items-center text-center w-full;
        height: 188px;
        .svg-spinner {
          height: 20px;
        }
      }

      .cdk-virtual-scroll-viewport {
        height: auto;
        min-height: 188px; // 46*4 +4
        max-height: 200px;

        &::-webkit-scrollbar-thumb {
          border-radius: 0px;
          -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
          background-color: var(--scrollbar-drag-bg);
        }
        &::-webkit-scrollbar {
          width: 6px;
          //height: 8px;
          background-color: var(--scrollbar-bg);
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
          border-radius: 0px;
          background-color: var(--scrollbar-bg);
        }
      }

      .manager-item {
        @apply flex flex-row justify-start items-center w-full;
        @apply m-0 p-0 border-b border-global-border;
        min-height: 46px;
        height: 46px;
      }

      .manager-image {
        @apply rounded-full mx-custom-8;
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
      }
    }
  }

  .group-ticket-details {
    @apply flex flex-col justify-start items-start w-full;

    .summary {
      @apply text-13;
    }
  }
}
