.coupon {
  .leg.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

#pool_coupon {
  .content_info {
    &.disabled_selections {
      @apply pointer-events-none;
    }
  }
}

.sports-pool-leg {
  @apply flex flex-row justify-between items-center;
  @apply py-custom-6 pr-custom-16 pl-custom-14;
  @apply border-b border-global-border;
  min-height: 66px;
  &.stats-no-data {
    @apply text-13 font-normal text-reduced-color;
    min-height: 16px;
  }
  &.last {
    @apply border-b-0;
  }
  .last & {
    @apply border-b-0;
  }
  &.auto-w {
    @apply w-auto;
  }

  @media screen and (max-width: 479px) {
    @apply py-custom-8;
    padding-right: 3%;
    padding-left: 3%;
  }

  .left-leg-info {
    @apply flex items-center;
    @media screen and (max-width: 479px) {
      @apply w-full;
    }
    .stats_icon {
      @apply mr-custom-16 text-accent-color text-20;
      width: 15px;
    }
    .time-legs {
      @apply flex;
      @media screen and (max-width: 479px){
        @apply flex-col-reverse;
      }
    }
    .coupon-text {
      @apply flex items-center;
      width: 95px;
      &.date {
        @apply text-13 font-normal text-reduced-color;
        @media screen and (max-width: 767px) {
          @apply mr-custom-6;
        }
        @media screen and (max-width: 479px) {
          @apply text-11;
          margin-top: 1px;
          width: 84px;
        }
      }
      &.competitors {
        @apply flex flex-col items-start text-container-color font-medium;
        width: 190px;
        @media screen and (max-width: 767px) {
          @apply w-auto;
        }
        @media screen and (max-width: 479px) {
          @apply w-auto text-13 leading-19;
        }
        &.stats {
          @apply w-auto;
        }

        &.binary-w {
          width: 180px;
          @media screen and (max-width: 767px) {
            @apply w-auto;
          }
        }
      }
    }
  }
  .right-btn-holder {
    @apply flex justify-center h-full;
    width: 220px;
    &.end {
      @apply justify-end;
      @media screen and (max-width: 767px) {
        @apply w-full;
      }
    }
    .coupon-explainer {
      @apply text-13 text-reduced-color;
    }
    &.avb-baseball {
      @apply justify-between; // 80%
      width: 150%;
    }
    .gt-hda {
      @apply mx-custom-4 w-1/3;
    }
  }
}

.coupon-btn {
  @apply flex justify-center items-center text-13 mx-custom-4 font-medium cursor-pointer rounded-container;
  @apply bg-selection-bg text-selection-color;
  @apply border border-selection-border;
  height: 34px;

  @media screen and (max-width: 479px) {
    height: 31px;
  }
  &.wm {
    @apply text-reduced-color;
  }
  &.active {
    @apply bg-selection-active-bg text-selection-active-color font-semibold;
    @apply border border-selection-active-border;
    .freeplay & {
      @apply bg-freeplay-bg text-container-reduced-color;
      @apply border-0;
    }
  }

  &._1x2 {
    //width: 33.3%;
    width: 100%;
  }

  &.binary {
    width: 100px;
    @media screen and (max-width: 767px) {
      width: 49%;
    }
  }

  &.cs {
    width: 48%;
    min-width: 63px;
    @apply mx-0 mb-custom-8 p-custom-6 text-13 text-center;

    @media screen and (max-width: 767px) {
      @apply mb-custom-8;
    }

    @media screen and (max-width: 479px) {
      @apply mb-custom-6 p-custom-8 text-11 w-auto;
      min-width: 48%;
    }

    &.any-other {
      @apply w-full mb-0 leading-13 text-center;
      min-width: 145px;

      @media screen and (max-width: 991px) {
        min-width: 110px;
        @apply mx-auto;
      }

      @media screen and (max-width: 479px) {
        min-width: auto;
      }
    }

    &.tennis-cs {
      width: 30%;
    }
  }

  &.mp-btts {
    width: 48%;
    @apply mx-0;
  }

  &.mp-hda {
    width: 31%;
    @apply mx-0;
  }

  &.mp-tfg {
    width: 18%;
    @apply mx-0 mb-custom-10;
  }

  &.mp-ttg {
    width: calc(14.2% - 5px);
    @apply mx-0;
  }

  &.mp-cdf {
    width: calc(16% - 5px);
    @apply mx-0 mb-custom-10;
  }

  &.mp-ht-ft {
    width: 31%;
    @apply mx-0 mb-custom-10;
  }

  &.avb-baseball {
    @apply mr-0 ml-0;
  }

  &.disabled {
    @apply bg-selection-bg text-selection-color;
  }

  &.ghost-stats-btn {
    @apply bg-transparent text-accent-color text-15;
    &.have-votes {
      margin-bottom: 21px;
    }
  }

  &.result {
    @apply text-15 font-semibold cursor-default bg-transparent;
    width: 70%;
    &.WIN {
      @apply bg-transparent ; // bg-selection-win-bg text-selection-win-color
    }
    &.LOSS {
      @apply bg-transparent ; // bg-selection-lost-bg text-selection-lost-color
    }
    &.DRAW {
      @apply bg-transparent ; // bg-selection-draw-bg text-selection-draw-color
    }
  }

  &.have-votes {
    @apply mb-0 rounded-bl-none rounded-br-none;
  }
}
 // votes
.vote-count {
  @apply flex flex-row justify-center;
  @apply mb-custom-8;
  @apply text-11 font-bold;
  @apply rounded-bl-btn-radius rounded-br-btn-radius;
  background: var(--coupon-votes-bg);
  color: var(--coupon-votes-text);
  min-height: 16px;
  height: 16px;
  max-height: 16px;
  &.racing {
    @apply mx-custom-4;
  }
  &.empty {
    min-height: 16px;
    height: 16px;
    max-height: 16px;
    background: var(--coupon-votes-bg-empty);
    color: var(--coupon-votes-text-empty);
  }
}

.vote-container {
  @apply flex justify-center;

  &._1x2 {
    //width: 33.3%;
    width: 100%;
  }

  &.binary {
    width: 100px;
    @apply mx-custom-4;
    @media screen and (max-width: 767px) {
      width: 49%;
    }
    .coupon-btn.binary {
      width: 100%;
    }

    .vote-count {
      @apply w-full mx-custom-4;
    }
  }

  &.cs {
    width: 48%;
    min-width: 63px;
    @apply mx-0 text-center;

    &.tennis {
      width: 30%;
    }

    .coupon-btn {
      width: 100%;
    }

    .vote-count {
      width: 100%;
      min-width: 63px;
    }

    @media screen and (max-width: 767px) {
      //@apply mb-custom-8;
    }

    @media screen and (max-width: 479px) {
      //@apply mb-custom-6 p-custom-8 text-11 w-auto;
      min-width: 48%;
      .vote-count {
        @apply w-auto;
        min-width: auto;
      }
    }

    &.any-other {
      @apply w-full mb-0 leading-13 text-center;
      min-width: 145px;
      width: 100%;
      .vote-count {
        min-width: 145px;
        width: 100%;
      }

      @media screen and (max-width: 991px) {
        min-width: 110px;

        @apply mx-auto;
        .vote-count {
          width: 100%;
          min-width: 110px;
          @apply mx-auto;
        }
      }

      @media screen and (max-width: 479px) {
        min-width: auto;
        .vote-count {
          min-width: auto;
        }
      }
    }

    &.tennis-cs {
      width: 30%;
    }
  }

  &.mp-btts {
    width: 48%;
    @apply mx-0;
    .coupon-btn {
      @apply w-full;
    }
    .vote-count {
      @apply leading-1 mx-0 w-full;
    }
  }

  &.mp-hda {
    width: 31%;
    @apply mx-0;
    .vote-count {
      @apply leading-1 mx-0 w-full;
    }
  }

  &.mp-tfg {
    width: 18%;
    @apply mx-0 mb-custom-10;
    .coupon-btn {
      @apply w-full;
    }
    .vote-count {
      @apply leading-1 mx-0 w-full;
    }
  }

  &.mp-ttg {
    width: calc(14.2% - 5px);
    @apply mx-0;
    .coupon-btn {
      @apply w-full;
    }
    .vote-count {
      @apply leading-1 mx-0 w-full;
    }

    @media screen and (max-width: 767px) {
      @apply mb-custom-8;
      width: calc(24% - 5px);
    }
  }

  &.mp-cdf {
    width: calc(16% - 5px);
    @apply mx-0 mb-custom-10;
    .coupon-btn {
      @apply w-full;
    }
    .vote-count {
      @apply leading-1 mx-0 w-full;
    }

    @media screen and (max-width: 767px) {
      @apply mb-custom-8;
      width: calc(32% - 5px);
    }
  }

  &.mp-ht-ft {
    width: 31%;
    @apply mx-0 mb-custom-10;
    .coupon-btn {
      @apply w-full;
    }
    .vote-count {
      @apply leading-1 mx-0 w-full;
    }
  }

  &.avb-baseball {
    @apply mr-0 ml-0;
    width: 48%;
    .coupon-btn {
      @apply w-full;
    }
    .vote-count {
      @apply leading-1 mx-custom-4 w-full;
    }
  }
}


.gt-hda {
  .vote-count {
    position: relative;
    left: 4px;
  }
}

.ou {
  .vote-count {
    @apply relative mx-custom-4;
  }
}


// AVB leg - esports

.content.avb-leg {
  @apply mb-custom-10;
  .avb-leb {
    .avb-header {
      @apply flex justify-between py-custom-10 px-custom-12 bg-highlight-bg text-highlight-color;
    }

    .avb-selections {
      @apply flex p-custom-16 justify-between items-end;

      .avb-competitor {
        @apply flex flex-col justify-center items-center;
        width: 43%;

        .avb-competutor-name {
          @apply font-semibold mb-custom-9;
        }

        .coupon-btn.avb {
          width: 100%;
        }

      }

      .avb-vs-block {
        @apply flex justify-center items-center italic font-semibold;
        width: 34px;
        height: 34px;
      }
    }
  }
}
