// Alert bar
.content.fp-alert {
  @apply flex mb-custom-16 px-custom-12 justify-between items-center cursor-pointer;
  @apply bg-freeplay-bg text-freeplay-color;
  height: 51px;
  transition: all 200ms ease;

  &:hover {
    filter: brightness(95%);
  }

  .fp-icon-bar {
    @apply flex justify-center items-center rounded-full text-23 p-custom-8 mr-custom-10;
    background: rgba(0,0,0,0.10);
    width: 35px;
    height: 35px;
  }

  .desktop-fp-banner-chevron {
    @apply flex justify-center items-center text-20;
    height: 20px;
    width: 20px;
  }
}

.content.mb {
  @apply mb-custom-24 p-0;
}

// Helper (right side)
.content.fp-explainer {
  @apply border-t-4 border-freeplay-bg;
  &.syndicates {
    @apply border-0;
  }
}

.fp-explainer {
  .fp-explainer-items {
    @apply mb-custom-4 px-custom-16 pt-custom-24 pb-custom-16;
    @apply bg-help-title-bg text-help-title-color;
    .text-block {
      @apply text-20 leading-27 font-semibold;
    }
  }

  .fp-earn-item {
    @apply flex items-start border-b border-global-border px-custom-16 py-custom-24;
    &:last-child{
      @apply border-transparent;
    }
    .fp-earn-number {
      @apply flex justify-center items-center rounded-full text-23 font-semibold font-accent mr-custom-16;
      @apply bg-bullet-freeplay-bg text-bullet-freeplay-color;
      width: 34px;
      min-width: 34px;
      height: 34px;
      min-height: 34px;
      &.syndicates {
        @apply bg-bullet-syndicates-bg text-bullet-syndicates-color;
      }
    }
    .text-block-item {
      @apply mb-custom-9 text-16 font-normal text-headings-color;
    }
  }
}

// Flexible list

.content.fp-token {
  @apply flex py-custom-16 pr-custom-16 pl-custom-12 justify-between items-center;
  &.expanded {
    @apply rounded-b-none;
  }
}

.content.fp-expand {
  margin-top: -24px;
}

.fp-token {
  .fp-card-details {
    @apply flex items-center;

    .sport-badge {
      @apply relative;
      .sport-holder {
        @apply relative flex p-custom-10 mr-custom-16 justify-center items-center bg-highlight-bg text-highlight-color;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        top: -7px;
      }

      .fp-card-indicator {
        @apply absolute flex justify-center items-center border-3 border-container-bg text-12 font-semibold rounded-full;
        @apply bg-freeplay-bg text-freeplay-color;
        width: 27px;
        height: 27px;
        left: 22px;
        top: 16px;
      }
    }

    .fp-card-pool-details {
      @apply pl-custom-16 border-l border-global-border;

      .pool-row {
        @apply flex mb-custom-3;

        .fp-card-emphasis {
          @apply text-16 font-semibold;
          &.type {
            @apply pl-custom-4;
          }
        }
      }

      .fp-card-expiry {
        @apply text-reduced-color leading-22;
      }
    }
  }
}

.content.fp-token.ghost {
  @apply relative opacity-30 pointer-events-none;
  filter: grayscale(1);
  .fp-card-details {
    .sport-badge {
      .sport-holder {
        .fp-token-badge-icon {
          @apply hidden;
        }
      }
      .fp-card-indicator {
        @apply text-transparent;
      }
    }
    .fp-card-pool-details {
      .pool-row {
        .fp-card-emphasis {
          @apply text-transparent bg-highlight-bg;
        }
      }
      .fp-card-expiry {
        @apply text-transparent bg-highlight-bg;
      }
    }
  }
  .btn.freeplay.fp-token {
    @apply text-transparent;
  }
}
