.widget.pool-history {
  @apply bg-container-bg text-container-color;
  .component-header-row {
    margin-bottom: 0 !important;
  }
  .dropdown-placeholder {
    @apply flex flex-row w-full mt-custom-24 justify-center items-center;
    @apply bg-select-bg text-select-color font-semibold;
    min-height: 40px;
  }
  .items {
    .pool-history-item {
      @apply flex flex-col justify-between;
      margin-bottom: 15px;
      .pool-history-leg {
        @apply flex flex-row mb-custom-2 justify-between;
        .pool-history-text {
          @apply text-12 leading-21;
        }
      }
    }
    .pool-history-progress {
      @apply w-full bg-progress-history;
      height: 10px;
      border-radius: 20px;
      .completed {
        height: 10px;
        border-radius: 20px;
        @apply text-progress-history-active bg-progress-history-started;
      }

      .official {
        @apply bg-progress-history-active;
      }

      &.initial {
        height: 10px;
        border-radius: 20px;
        @apply bg-progress-history-started;
        .completed {
          @apply bg-progress-history-started;
        }
        .official {
          @apply bg-progress-history-started;
        }
      }
    }
  }
}
