.w-dropdown {
  @apply inline-block relative text-left ml-auto mr-auto;
  z-index: 900;
}

.w-dropdown-toggle {
  @apply inline-block cursor-pointer justify-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 40px;

  .icon {
    width: 20px;
    height: 20px;
    @apply flex items-center justify-center mr-custom-4 text-link-color text-20;
  }

  .toggle-text {
    @apply text-14 relative;
  }
}

.w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
  @apply relative text-left mr-auto ml-auto whitespace-nowrap text-filter-color;
  vertical-align: top;
  text-decoration: none;
  padding: 20px;
}

.faq {
  @apply flex flex-row w-full justify-between items-center overflow-hidden px-custom-16 py-0;
  @apply text-16 font-semibold text-filter-color;
  @apply bg-filter;
  height: 55px;
  white-space: break-spaces;
}

.w-dropdown-toggle:focus {
  outline: 0;
}

.content.filter-dropdown {
  @apply w-full mb-custom-24 overflow-hidden z-0;
  min-height: 55px;
  &.small-margin {
    @apply mb-custom-4;
  }
}

.faq-body {
  @apply relative block w-full bg-transparent;
}

.faq-body.w--open {
  @apply bg-transparent;
}

.w-dropdown-list.w--open {
  @apply block;
}

.faq-body-cont {
  @apply flex justify-between p-custom-16;
  @apply bg-filter-expanded text-filter-color;
  @apply border-t border-global-border;
}

.filter-select {
  width: 32%;
  .select-label {
    @apply mb-custom-3;
    @apply text-13 text-select-label;
  }
  .new-select {
    @apply text-15 font-semibold text-select-color;
    .select-dropdown {
      @apply bg-select-bg;
      @apply font-semibold text-select-color;
    }
  }

  &.dual {
    width: 48%;
  }

  &.single {
    @apply w-full;
  }
}
