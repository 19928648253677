.syndicate-split {
  width: 900px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    max-width: 900px;
  }
  @media screen and (max-width: 479px) {
    width: 100%;
  }
}



// Tag element

.tag {
  @apply flex absolute justify-center items-center rounded-full;
  @apply bg-tag-bg text-tag-color text-13 font-medium;
  @apply border border-tag-border;
  width: 25px;
  height: 25px;
  z-index: 1;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.23);
  &.payout {
    left: 42px;
    top: 15px;
  }
  &.promotion {
    left: 28px;
    top: -4px;
  }
}

  // Syndicate menu

.content.syndicate-nav-bar {
  @apply flex w-full max-w-main-content mb-custom-24 mr-auto pl-custom-12 justify-between items-center;
  @apply bg-container-bg;
  &.start {
    @apply justify-start;
  }
  &.scrollable {

  }
  @media all and (max-width: 767px) {
    @apply ml-0 pl-custom-12 border-b border-global-border mr-0 mb-custom-12 flex max-w-full;
  }
  @media all and (max-width: 479px) {
    @apply mt-custom-12 mb-custom-12 pr-custom-12 flex;
  }

  &.fill {
    @apply max-w-full;
  }

  .center-links {
    @apply flex h-full overflow-x-auto overflow-y-hidden;

    .syndi-nav-link {
      @apply relative flex mr-custom-5 pt-custom-4 justify-center;
      @apply border-b-3 border-h-menu-border;
      @apply text-h-menu-color cursor-pointer;
      top: 1px;
      height: 54px;
      &.w-inline-block {
        max-width: 100%;
      }

      &.w--current, &.active {
        @apply text-h-menu-active-color border-h-menu-active-border cursor-default; // TODO: Check here
      }

      .syndicate-nav-element {
        @apply relative flex h-full py-custom-8 px-custom-16 items-center justify-center text-14 font-medium;
      }

      .syndi-nav-img {
        width: 34px;
        height: 34px;
        min-height: 34px;
        min-width: 34px;
        margin-right: 13px;
        border-radius: 20px;
      }

      .tag.syndi-nav {
        left: 38px;
        top: 2px;
        @media all and (max-width: 479px) {
          @apply hidden;
        }
      }
    }
  }
}

// search & forms

input[type="search"] {
  -webkit-appearance: none;
}

.w-input, .w-select {
  @apply block w-full py-custom-8 px-custom-12;
  @apply text-14 text-reduced-color align-middle;
  @apply border border-global-border;
  line-height: 1.42857143;
}

input.w-button {
  -webkit-appearance: button;
}

::-webkit-input-placeholder {
  color: white; // TODO: Check here if it's all good in black version
}

.search-button {
  @apply hidden;
}

.syndi-search-item {
  @apply relative;
  .search-icon {
    @apply absolute text-reduced-color text-20;
    left: 11px;
    top: 20px;
    width: 20px;
  }

  .search-2 {
    @apply mb-0;
  }
  @media screen and (max-width: 479px) {
    @apply flex-1;
  }

  &.desktop {
    @media screen and (max-width: 479px) {
      @apply hidden;
    }
  }

  &.mobile {
    @apply hidden;
    @media screen and (max-width: 479px) {
      @apply flex justify-end relative w-full;
      height: 54px;
    }
    .search-input {
      @apply absolute mb-0 rounded-none w-full hidden;
      @apply bg-search text-search-text;
      @apply border border-transparent;
      width: 0;
      height: 40px;
      right: 24px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      z-index: 1;
      transition: width 2s ease;
    }

    .block {
      @apply flex justify-center items-center bg-search relative;
      border-radius: 20px;
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      height: 40px;
      top: 8px;
      right: 6px;
      z-index: 2;
    }
    &:hover, &:focus, &:active {
      .search-input {
        @apply flex;
        width: 290px;
      }
      .block {
        @apply border border-global-border;
      }
    }
  }
}


// syndicate list item

.syndi-join-card {
  @apply w-full mb-custom-24 px-custom-14 pt-custom-14 pb-custom-12;
  @apply bg-card-bg text-card-color cursor-pointer;
  @media all and (max-width: 479px) {
    @apply text-13;
  }

  .direct-join & {
    @apply rounded-tl-none rounded-tr-none;
  }

  .syndi-join-top-row {
    @apply relative flex pb-custom-14 justify-between items-center;
    @apply border-b border-global-border;

    .captain-information {
      @apply relative flex items-center;
      width: 70%;

      img {
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
      }

      .captain-avatar {
        width: 32px;
        height: 32px;
        border-radius: 20px;
        object-fit: cover;
        @apply mr-custom-14 relative;
      }

      .join-card-creator {
        @apply text-card-color leading-22 font-semibold;
      }

      .join-card-prize {
        @apply font-normal;
      }

      .value {
        @apply text-card-color font-semibold;
      }

      &:hover {
        .join-card-creator {
          @apply text-accent-color;
        }
      }
    }

    .funded-percentage {
      @apply text-14 font-semibold text-right rounded-b-nav_link;
      &.join-list {
        @apply text-16 whitespace-nowrap;
      }

      // .highlight {
      //   @apply text-accent-color font-semibold;
      // }
    }
  }


  .extra-details-bar {
    @apply flex py-custom-14 justify-between items-center;
    @apply border-t-0 border-b border-global-border;

    .ownership-block {
      @apply px-custom-12 py-custom-6 rounded-container font-semibold;
      @apply bg-warning-bg text-warning-color;
    }

    @media all and (max-width: 479px) {
      @apply py-custom-14 items-center;
    }
  }

  .join-card-bottom-row {
    @apply flex mt-custom-14 mb-custom-4 justify-between items-center;

    .join-card-cost {
      @apply flex flex-row items-center font-normal;
    }

    .contributors-div {
      @apply flex justify-end items-center;

      .count-icon {
        @apply flex mr-custom-6 justify-center items-center rounded-full;
        @apply text-reduced-color;
        width: 17px;
        height: 17px;
      }
    }
  }

  .syndicate_status.WINNING, .syndicate_status.PAYOUT {
    @apply text-offer-color bg-offer-bg;
    @apply p-custom-4 rounded-container;
  }


  .percentage_filled {
    .percentage_filled & {
      @apply font-semibold;
      .highlight {
        @apply text-accent-color;
      }
    }
  }

  .playing_for_prize {
    .playing_for_prize & {
      @apply font-semibold;
      .highlight {
        @apply text-accent-color;
      }
    }
  }

  .contributors {
    .icon {
      @apply font-semibold text-20;
    }
    .contributors & {
      @apply font-semibold;
      .highlight {
        @apply text-accent-color;
      }
    }
  }

  .cost {
    .cost & {
      @apply font-semibold;
      .highlight {
        @apply text-accent-color;
      }
    }
  }

  .starting_soon  {
    .starting_soon & {
      @apply font-semibold;
      .highlight {
        @apply text-accent-color;
      }
    }
  }

  .most_recent  {
    .most_recent & {
      @apply font-semibold;
      .highlight {
        @apply text-accent-color;
      }
    }
  }

  .starting_soon, .most_recent, .playing_for_prize, .cost {
    .lessen {
      @apply text-container-color;
    }
  }
}

// syndicate help

.content.widget.my-share {
  @apply pb-custom-10 items-stretch;

  .widget-header {
    @apply text-17 leading-23 font-semibold text-left;
  }
}

// syndicate betslip

//input[type=number]::-webkit-inner-spin-button,
//input[type=number]::-webkit-outer-spin-button {
//  -webkit-appearance: none;
//  margin: 0;
//}


.content.desktop-contribute {
  @apply mb-custom-24 px-custom-16 py-custom-24;

  .widget-header {
    @apply text-17 leading-23 font-semibold text-left;
  }

  &.ticket-confirmed {
    @apply border-t-4 border-success-color;

    .confirmation {
      @apply flex mb-custom-24 flex-row items-center;

      .icon {
        @apply inline-block p-custom-5 bg-primary-color text-text-color text-17 text-center box-content whitespace-nowrap mr-custom-16 leading-19;
        border-radius: 50%;
        min-width: 20px;
        min-height: 20px;
      }

      ._text {
        @apply text-text-color text-17 leading-23 font-semibold;
        @media all and (max-width: 479px) {
          @apply text-15;
        }
      }
    }
  }
}

// contribution progress & input

.contribute-content {
  @apply w-full mt-custom-20;

  .content.syndicate-contributions {
    @apply mb-custom-8;
  }


  .funding-bar {
    @apply relative mb-custom-24 bg-progress-join;
    height: 8px;
    border-radius: 20px;
    overflow:hidden;

    &.internal {
      @apply rounded-tr-none rounded-br-none bg-progress-join-portion;
      z-index: 3;

      &.your-portion {
        @apply absolute rounded-none bg-progress-join-funded;
        border-radius: 20px;
        top: 0;
        z-index: 1;
        -webkit-transition: width 400ms ease-in-out;
        -moz-transition: width 400ms ease-in-out;
        -o-transition: width 400ms ease-in-out;
        transition: width 400ms ease-in-out;
      }
    }
  }

  .manual-contribution {
    @apply flex mb-custom-24 rounded-container;
    @apply bg-container-bg text-container-color;
    @apply border border-global-border;
    height: 48px;

    .contribute-input {
      @apply flex w-1/2 items-center justify-start pl-custom-10;
      &.left {
        @apply flex pl-0 border-r border-global-border rounded-tl-container rounded-bl-container;
      }
      &.right-edge {
        @apply bg-container-bg text-container-color rounded-tr-container rounded-br-container;
      }

      .currency {
        @apply flex text-17 font-medium items-center justify-center rounded-tl-container rounded-bl-container h-full;
        @apply bg-input-addon-bg text-text-color;
        min-width: 31px;
      }

      .contrib {
        @apply w-full text-reduced-color text-13 text-center;
        margin-left: -4px;
      }
    }
  }
}


// Profile

.private-syndicate {
  @apply absolute flex justify-center items-center top-0 left-0 rounded-br-container;
  @apply bg-b-primary-bg text-b-primary-color p-custom-4 font-semibold text-13;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  z-index: 1;
}

.pinner-selector {
  @apply flex justify-center items-center shadow-content-shadow dark:shadow-dark-content-shadow text-20;
  @apply bg-container-bg text-reduced-color;
  width: 40px;
  max-height: 235px;
  &.selected {
    @apply text-accent-color;
  }
}

.border-selected {
  @apply absolute top-0 left-0 w-full h-full rounded-container;
  @apply border border-primary-color;
  z-index: 1;
}

.content.profile {
  .profile-alert-header {
    @apply flex py-custom-18 px-custom-16 justify-between items-center;
    @apply bg-container-bg text-container-color;
    &.rebate {
      min-height: 60px;
      @apply bg-info-bg text-info-color font-medium;

      .icon-positive-roi {
        @apply flex justify-center items-center p-custom-9 mr-custom-16 overflow-hidden relative rounded-full;
        @apply text-accent-color bg-container-bg text-17;
        width: 36px;
        height: 36px;
        min-height: 36px;
        min-width: 36px;
      }
    }

    .info {
      max-width: 470px;
      @apply flex flex-row justify-center items-center;
    }

    .learn-more {
      @apply flex ml-custom-18 justify-end flex-wrap cursor-pointer;
      @apply font-semibold text-link-color;
      width: 100px;
    }

    .rebate_tier {
      @apply flex justify-center items-center;
      // TODO: Since these are CB only, were not changed
      width: 30px;
      height: 30px;
      border-radius: 30px;
      margin-right: 7px;
      color: #FFF;
      background: var(--color-brand-key);
      &.rebate_tier_vip {
        font-size: 10px;
      }
    }
  }

  .profile-element {
    @apply relative flex flex-col items-start px-custom-24 pb-custom-24;
    @apply bg-container-bg text-container-color;
    padding-top: 56px;
    // profile image cover
    .profile-cover-img {
      @apply absolute left-0 top-0 w-full;
      @apply border-b border-global-border;
      @apply bg-cover bg-no-repeat rounded-tl-nav_link rounded-tr-nav_link;
      top: -1px;
      background-position: 50% 50%;
      transform: rotate(
          0deg
      );

      &.my-profile {
        height: 121px;
        @apply rounded-tr-none rounded-tl-none bg-cover bg-no-repeat;
      }
    }
    // twitter button
    .sports-twitter-row {
      @apply absolute left-auto bottom-0 z-0 flex flex-row justify-between items-start;
      top: 103px;
      right: 2%;
      height: 50px;
      .row-items {
        @apply flex;
        .twitter-badge {
          @apply relative flex py-custom-10 px-custom-14 justify-center items-center rounded-container top-1;
          @apply bg-pill-gray-bg text-pill-gray-color text-14;

          .twitter-icon {
            @apply mr-custom-5 text-14 relative;
            color: #FFF; // No need variable since it's a brand colour
            top: 2px;
            //width: 15px;
            background: #000;
            padding: 4px;
            border-radius: 2px;
            width: 22px;
            height: 22px;
          }

          .twitter-username {
            @apply text-14 font-medium;
          }

          @media all and (max-width: 480px) {
            position: absolute;
            right: 0;
            top: -30px;
          }
        }
      }
    }

    .top-content {
      @apply flex relative mb-custom-16;

      .user-avatar {
        @apply relative rounded-full object-cover;
        width: 36px;
        min-width: 36px;
        height: 36px;
        min-height: 36px;
        &.profile {
          @apply border-3 border-global-border-reduced;
          width: 120px;
          height: 120px;
        }
      }
    }

    .bio {
      @apply relative w-full;

      .captain-name-profile {
        @apply mb-custom-16;

        .bold-text {
          @apply text-23 font-semibold text-text-color;
        }
      }
      .profile-bio {
        @apply mb-custom-30 text-15 leading-23 font-normal;
      }
      .profile-button-row {
        @apply flex mb-custom-30 justify-between;

        a.btn {
          width: 48%;
        }
      }
    }

    .my-club-details {
      @apply flex items-end;
      border-top: 1px solid var(--global-border);
      padding-top: 17px;
      width: 100%;
      @media all and (max-width: 479px) {
        @apply w-full overflow-x-auto p-custom-10;
      }

      .profile-stat-item {
        &.followers {
          @apply cursor-pointer pr-custom-12 mr-custom-12 border-r border-global-border;
        }
        &._1st-syndicate {
          @apply pr-custom-12 mr-custom-12 border-r border-global-border;
        }

        .my-club-detail-label {
          @apply flex items-center text-13 leading-17;
          height: 22px;

          &.value1 {
            @apply text-15 font-medium text-text-color;
          }

          &.no-click {
            @apply pointer-events-none text-text-color;
          }

          .link {
            @apply text-link-color ml-custom-7;
          }
        }
      }
    }

    // edit

    .profile-bio-edit {
      .textarea {
        @apply relative w-full mb-custom-24 py-custom-18 pl-custom-24 rounded-nav_link;
        @apply bg-global-border-reduced text-container-color;
        @apply focus:ring-0 focus:border-transparent focus:outline-none;
        padding-left: 22px;
        padding-right: 51px;
      }

      .field-indicator {
        @apply absolute left-auto right-0 bottom-0 flex px-custom-5 justify-center items-center rounded-nav_link;
        @apply bg-container-bg text-reduced-color italic;
        top: 28px;
        right: 1px;
        height: 30px;
        z-index: 9;
      }

      .profile-bio {
        @apply text-15 font-normal leading-23 mb-custom-30;
        &.edit {
          @apply mb-0;
        }
      }
    }

    .twitter-edit-field {
      @apply relative;
      .input-text {
        @apply flex mb-custom-24 w-full py-custom-16 pl-custom-16 pr-custom-24 rounded-nav_link;
        @apply border-global-border-reduced focus:ring-0 focus:border-transparent focus:outline-none;
        padding-right: 22px;
        text-indent: 36px;
      }

      .twitter-edit-icon {
        @apply flex items-center mr-custom-12 text-27;
        color: #FFF; // No need variable since it's a brand colour
        position: absolute;
        //height: 53px;
        left: 12px;
        //width: 20px;

        background: #000;
        width: 28px;
        height: 28px;
        padding: 4px;
        font-size: 22px;
        top: 25%;
        /* color: #FFF; */
        border-radius: 2px;
      }
    }

    .profile-img-overlay {
      @apply absolute z-10 flex justify-center items-center border-3 rounded-full;
      @apply border-container-bg text-container-color text-27;
      width: 120px;
      height: 120px;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .image-link {
    @apply block mr-custom-6 mb-custom-4;
    width: 50px;
    height: 50px;

    .preset-avatar {
      @apply rounded-full;
      min-width: 50px;
      width: 50px;
      min-height: 50px;
      height: 50px;
    }
  }

  // image uploader
  .drop-area {
    @apply flex px-custom-12 py-custom-12 flex-col justify-center items-center rounded-container;
    @apply border border-dashed border-global-border;

    &.highlight {
      @apply border-accent-color;
    }

    i {
      @apply block text-center;
      font-size: 60px;
    }

    .uploader-form {
      @apply w-full text-center;

      #fileElem {
        @apply hidden;
      }

      label {
        @apply cursor-pointer font-semibold;
      }
    }
  }
}

// my captains

.syndicate-modal {
  @apply flex h-auto flex-col justify-between rounded-container;
  @apply bg-container-bg text-container-color shadow-content-shadow dark:shadow-dark-content-shadow relative;
  width: 320px;
  max-height: 510px;
  min-height: 410px;
  .no-modal & {
    @apply w-full;
    max-height: unset;
    min-height: unset;
  }
}

.syndicate-modal, .leaderboard-item {
  .modal-top {
    .modal-content {
      @apply relative px-custom-16 pt-custom-24 pb-0;
      &.modal-header {
        @apply flex pb-custom-10 items-center rounded-t-container;
        @apply bg-list-item-title-bg text-list-item-title-color;
      }

      .create-modal-header {
        @apply mb-custom-8 text-17 leading-23 font-semibold text-left;
      }

      .back-cross {
        @apply absolute bottom-auto left-auto w-full text-23;
        @apply text-reduced-color cursor-pointer;
        width: 18px;
        top: 17px;
        right: 14px;
      }
    }
  }

  .modal-bottom {
    @apply flex overflow-y-scroll flex-col h-full justify-between items-end flex-1;
    .modal-content {
      @apply relative px-custom-16 pt-custom-24 pb-0;
      &.bottom {
        @apply flex w-full h-full pb-custom-24 flex-col flex-1;
      }
      &.membership {
        @apply pt-custom-16 justify-start;
      }
    }

    .approval-club-item {
      @apply flex py-custom-12 justify-between items-center;
      @apply border-b border-global-border rounded-nav_link;
      .list-item-details {
        @apply flex w-full items-center whitespace-nowrap relative;
        &.modal {
          @apply flex-row;
        }

        .approval-avatar {
          width: 28px;
          height: 28px;
          min-width: 28px;
          min-height: 28px;
          @apply object-cover rounded-full mr-custom-12;
        }

        .club-member {
          @apply flex flex-col items-start;
          .captain-name {
            @apply flex overflow-hidden items-center text-14 leading-17 text-center font-semibold whitespace-pre-line;
            height: 25px;
            @media all and (max-width: 450px) {
              @apply text-11;
            }
          }
        }

        .follow-details {
          @apply flex flex-col text-13 justify-center items-center ml-custom-16;
          .highlight {
            @apply font-semibold;
          }
        }
        .tag.my-captains {
          left: 13px;
          top: -3px;
          width: 22px;
          height: 22px;
          font-size: 12px;
        }
        .text-box-small {
          @apply text-11 shadow-content-shadow dark:shadow-dark-content-shadow;
        }
      }

      .alert-btn-group {
        @apply flex justify-between;
        width: 220px;
        &.right-align {
          @apply justify-end;
          width: 150px;
        }
      }
    }
  }
}
// Modifier for captains list when you're not manager in order to re-use the modal content
.syndicate-modal {
  .no-modal & {
    .modal-top {
      .modal-content {
        .back-cross {
          @apply hidden;
        }
      }
    }
    .modal-bottom {
      overflow-y: unset;
    }
  }
}

.leaderboard-item {
  transition: height 1s ease;
  .modal-bottom {
    @apply overflow-hidden;
    .modal-content {
      @apply px-0;
      &.bottom.membership {
        @apply pb-0;
      }
    }
    .approval-club-item {
      @apply border-b-0 bg-container-bg text-container-color px-custom-12;
      &.flat {
        @apply rounded-b-none;
      }
      .open-action {
        @apply rounded-container mr-custom-12 flex justify-center items-center p-custom-12 shadow-btn-shadow cursor-pointer;
        @apply bg-pill-gray-bg text-pill-gray-color text-14;
        width: 12px;
        min-width: 12px;
        height: 12px;
        min-height: 12px;
      }
      .rank {
        @apply flex flex-row justify-between items-center font-semibold p-0 pr-custom-6;
        width: 42px;
      }

      .position {
        @apply ml-custom-4 mr-custom-4;
        &.rank_up {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--success-color) transparent;
        }
        &.rank_down {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: var(--error-color) transparent transparent transparent;
        }
        &.rank_same {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 2px 2px 0 4px;
          border-color: var(--container-color) var(--container-color) var(--container-color) var(--container-color);
        }
      }

      .percentage {
        @apply font-semibold;
      }
      @media all and (max-width: 479px) {
        .list-item-details.modal .club-member {
          // ask aurel
          // @apply hidden;
        }
        .percentage {
          @apply mr-custom-12;
        }
      }
    }
  }
}

.leaderboard-item {
  .approval-club-item {
    .list-item-details.modal {
      .tag.my-captains {
        left: 48px;
        top: -8px;
      }
    }
  }
  .extra-info {
    @apply flex flex-row w-full justify-between items-center;
    @apply bg-container-bg text-container-color rounded-b-nav_link p-custom-12;
    @apply border-t border-global-border;
    .item-content {
      @apply px-custom-8 flex flex-col items-start justify-evenly;
      @apply border-r border-global-border;
      min-height: 60px;
      &.last {
        @apply border-r-0;
      }
    }
    .title {
      @apply text-12 md:text-14 font-semibold;
    }
  }
}


.showFollowing-modal {
  .cb-dialog {
    max-width: 320px !important;
    .cb-content {
      @apply p-0 bg-transparent;
    }
  }
}


// captain create

.content.widget.captain-create {
  @apply p-0 mb-0;
  .component-header-row {
    @apply p-custom-12 pb-0;
  }

  .captain-create {
    &__content {
      @apply px-custom-12;
    }
    &__inputs {
      @apply px-custom-12 relative mt-custom-16 w-full;
      .input-icon {
        @apply absolute inset-y-0 flex items-center pl-custom-8;
        left: 12px;
      }
    }
    &__actions {
      @apply w-full border-t border-global-border flex justify-between items-center mt-custom-16 p-custom-12;
      &.centered {
        @apply justify-center;
      }
    }
    &__errors {
      @apply w-full p-custom-12 mb-custom-8;
      .error {
        @apply w-full p-custom-12;
        @apply bg-error-bg text-error-color;
      }
    }

    &__image-list {
      @apply w-full p-custom-12 relative;
      .profile_img_content {
        @apply p-custom-12;

        .image-placeholder {
          @apply relative inline-flex overflow-hidden rounded-full;

          .edit-image {
            @apply absolute left-0 bottom-0 h-auto w-full flex justify-center items-center;
            @apply text-14 text-center bg-container-bg text-container-color;
            z-index: 10;
          }
        }
      }

      .image-customizer {
        .image-list {
          @apply flex flex-col justify-center items-center w-full border border-dashed border-global-border p-custom-12;
        }

        #avatar-uploader {
          .uploader-form {
            @apply w-full text-center;

            #fileElem {
              @apply hidden;
            }

            label {
              @apply cursor-pointer font-semibold;
            }
          }
        }

        .image-link {
          @apply block mr-custom-6 mb-custom-4;
          width: 50px;
          height: 50px;

          .preset-avatar {
            @apply rounded-full;
            min-width: 50px;
            width: 50px;
            min-height: 50px;
            height: 50px;
          }
        }

        &.highlight {
          @apply border-accent-color;
        }

        i {
          @apply block text-center;
          font-size: 60px;
        }
      }
    }
  }
}


// betslip

.betslip-container {
  &.syndicate-betslip {
    @apply flex flex-col;

    .content.betslip {
      .widget-header {
        @apply text-17 leading-23 font-semibold text-left;
      }
      .contribute-content {
        @apply w-full mt-custom-24;
      }

      .funding-bar-container {
        @apply flex flex-row w-full justify-between items-center mb-custom-24 relative;
        .funding-bar {
          @apply mb-0 w-full;
        }
        .total-value {
          @apply flex flex-col justify-center items-end text-12  leading-13;
          width: 100px;
        }
      }

      .syndicate-checkboxes {
        @apply mt-custom-12 mb-custom-24;
        .syndicate-confirm-item {
          @apply flex mb-custom-12 items-center font-medium;
          &.first {
            @apply pb-custom-18 border-b border-global-border;
          }
        }
      }
    }
  }
}
// syndicate pool status

.payout-alert {
  @apply flex w-full items-center py-custom-14 pr-custom-12 pl-custom-14 font-semibold;
  @apply border-t-3 border-success-color;
  @apply bg-success-bg;
  &.url-page {
    @apply w-auto pl-custom-12 rounded-none;
  }
  .helper-text {
    .alert-span {
      @apply font-semibold text-text-color;
    }
  }
}
