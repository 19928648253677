select {
  &.blue {
    // TODO: Need improvements to use css variable color
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  }
}

[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  @apply bg-app-bg;
}

.available-card, .twitter-badge, .payout-card, .list-item, .syndicate-card, .syndi-join-card, .designated-captain-item {
  @apply shadow-content-shadow dark:shadow-dark-content-shadow;
}

.betslip-disabled {
  @apply pointer-events-none opacity-50;
}

.item-betslip {
  width: 32%;
  &.freeplay {
    width: 64%;
  }
  &.dual {
    width: 48%;
  }
  .full & {
    width: 50%;
    @apply mx-custom-5;
  }
}

.content.warning-alert {
  @apply my-0 p-custom-16 border-t-3;
  @apply bg-warning-bg border-warning-border text-warning-color;

  &.mobile {
    @apply mb-custom-12;
  }
  &._mt {
    @apply mt-custom-16;
  }
}

.content.error-alert {
  @apply bg-error-bg text-error-color p-custom-16 border-0 border-error-border;
}

.content.info-alert {
  @apply p-custom-16 border-0;
  @apply bg-info-bg text-info-color border-info-border;
  &.mobile {
    @apply mb-custom-12;
  }
  &.tb {
    @apply border-t-3;
  }
  &.lb {
    @apply border-l-3;
  }
  &.mb-none {
    @apply mb-0;
  }
  &._mt {
    @apply mt-custom-16;
  }
}

// no content on website
:root {
  --pools-no-content-bg-color: #212023;
  --pools-no-content-text-color: #FFF;
}
body.no-content {
  .mobile-menu-wrapper, .desktop-fixed-nav {
    @apply hidden;
  }
  .page {
    @apply p-0;
    .page-content {
      @apply w-full;
    }
  }

  .pool-no-content {
    @apply relative bg-repeat flex flex-row justify-center;
    min-height: 500px;
    background-image: none;
    background-size: auto;
    background-color: var(--pools-no-content-bg-color);

    .container {
      @apply absolute w-full py-0 px-custom-24 flex flex-col justify-center shadow-none border-0;
      @apply max-w-app-max-width mr-auto ml-auto;
      height: 500px;
    }

    .widget-header {
      @apply text-29;
      @apply text-headings-color;
      i {
        @apply text-accent-color;
      }
    }

    .white {
      color: var(--pools-no-content-text-color);
    }

    .bg-purple {
      height: 500px;
      width: 40%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .bg-image {
      height: 500px;
      width: 150%;
      background-color: var(--pools-no-content-bg-color);
      background-image: url($pools-no-content-bg-image);
      background-position: 0% 50%;
      background-size: cover;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &.full-height-page {
    @apply h-screen overflow-hidden;
    background-color: var(--pools-no-content-bg-color);

    #merchant, .page, .page-content, view-pools-no-content, .pool-no-content, .bg-purple, .bg-image {
      height: 100%;
    }
  }
}

.fake-checkbox {
  @apply inline-flex justify-center items-center rounded-container bg-transparent border border-global-border-reduced mr-custom-6;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  &.checked {
    @apply text-global-border-reduced bg-primary-color;
  }

  &.small {
    width: 17px;
    min-width: 17px;
    min-height: 17px;
    height: 17px;
    &.checked {
      @apply text-12;
    }
  }
}

.w-form {
  @apply m-0 mb-custom-16;

  label {
    @apply mb-custom-5 font-semibold block;
    &.field-label {
      @apply hidden flex-row-reverse;
    }
    &.tiebreaker-qsn {
      @apply mb-custom-12;
    }
  }
  .search-input {
    @apply mb-0 border border-transparent bg-search text-search-text;
    width: 332px;
    height: 55px;
    padding-left: 44px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .tiebreaker-input {
    @apply mb-0 border border-transparent bg-search text-search-text rounded-container;
    &.ng-touched.ng-invalid {
      border: 1px solid var(--color-warning);
    }

    &.ng-untouched {
      border: 1px solid var(--container-color);
    }
  }

  .w-form-errors {
    color: var(--warning-color);
    background: var(--color-warning);
    @apply p-custom-4 rounded-container mt-custom-8;
  }
}
