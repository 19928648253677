.light {
  // Fonts - make sure the font(s) have the following variants included: 400, 500, 600 - otherwise will break (not look good)
  // If there's a single font - use same for both variables
  --font-family: 'Open Sans';
  --font-family-accent: 'Open Sans';
  --primary-color: #25ac61;
  --accent-color: #015d3c;
  --text-color: #000;
  // Main containers
  --app-bg: #EFF0E9;
  --container-bg: #FFF;
  --container-color: #000;
  --container-reduced-color: #808080;
  --list-item-bg: #FFF;
  --list-item-color: #000;
  --list-item-title-bg: #25ac61;
  --list-item-title-color: #FFF;
  --card-bg: #FFF;
  --card-hover-bg: #f7f7fa;
  --card-color: #000;
  --pool-header-color: #FFF;
  --pool-header-lessen-color: #b1b1b1;
  --ud-bg: #f5f5f5;;
  --ud-color: #015d3c;
  // Buttons
  --b-primary-bg: #ef3f49;
  --b-primary-color: #fff;
  --b-secondary-bg: #25ac61;
  --b-secondary-color: #FFF;
  --b-tertiary-bg: #FFF;
  --b-tertiary-color: #666666;
  --link-color: #25ac61;
  --b-share-bg: #f7f7fa;
  --b-share-color: #000;
  // Specific texts
  --rollover-color: #25ac61;
  --bdg-color: #25ac61;
  --boosted-color: #25ac61;
  --reduced-color: #525661; // Not back, a but the gray that's visible (eg: where's gray text)
  --timer-zero: #920000;
  --timer-countdown: #04c559;
  --highlight-bg: #f7f7fa; // it's used to highlight an element, icon, section. Eg: preview-leg-details
  --highlight-color:#808080;
  // Specific colors - that are used only for a single purpose
  --global-border: #e6e6e6;
  --global-border-reduced: #e9e9e9;
  --stats: #25ac61;
  --tag-bg: #25ac61;
  --tag-color: #FFF;
  --tag-border: #FFF;
  --roi-bg: #eaf7f0; // On widgets cards (eg: Syndicate wins)
  --roi-color: #04c559;
  --offer-bg: #25ac61;
  --offer-color: #FFF; // Some of these are reversed for convenience. Please check usage
  --inplay-bg: #21a0ff;
  --inplay-color: #FFF;
  --voided-bg: #000;
  --voided-color: #FFF;
  --placed-bg: #f5f5f5;
  --placed-color: #25ac61;
  --refunded-bg: #FFF;
  --refunded-color: #000;
  --return-arrow-color: #ef3f49;
  --pill-gray-bg: #F5F5F5; // Eg: Gray used on 'Big syndicates payouts' contributors; twillter pill
  --pill-gray-color: #000;
  --help-title-bg: #F5F5F5;
  --help-title-color: #000;
  --sep-badge-bg: #015d3c;
  --sep-badge-color: #FFF;
  // Social share - this could have been fixed since are brand colours
  --share-wa-bg: linear-gradient(180deg, #04c559, #2ed176);
  --share-wa-color: #FFF;
  --share-tw-bg: linear-gradient(12deg, #000, #000);
  --share-tw-color: #FFF;
  --share-fb-bg: linear-gradient(12deg, #2172ff, #00a1ff);
  --share-fb-color: #FFF;
  --share-link-bg: #25ac61;
  --share-link-color: #FFF;
  // Notification containers (error, warning, info)
  --warning-bg: #F4F438;
  --warning-color: #000;
  --warning-border: #F4F438;
  --info-bg: #F5F5F5;
  --info-color: #015d3c;
  --info-border: rgba(37,172,97,.24);
  --error-bg: #ffe9e9;
  --error-color: #f04149;
  --error-border: #ffe9e9;
  --success-bg: #F5F5F5;
  --success-color: #25ac61;
  // Freeplay
  --freeplay-header-bg: #190235;
  --freeplay-header-color: #FFF;
  --freeplay-bg: #f1c400;
  --freeplay-color: #000;
  --bullet-freeplay-bg: #190235; // for help
  --bullet-freeplay-color: #f1c400;
  // Sydnicates
  --syndicates-main-color: #015d3c; // check usage before using this variable
  --bullet-syndicates-bg: #F5F5F5; // for help
  --bullet-syndicates-color: #808080;
  // Selections
  --selection-bg: #e7e9ea;
  --selection-color: #4a4a4a;
  --selection-border: transparent;
  --selection-hover-bg: #e7e9ea;
  --selection-hover-color: #4a4a4a;
  --selection-active-bg: #25ac61;
  --selection-active-color: #fff;
  --selection-active-border: transparent;
  --selection-win-bg: #25ac61;
  --selection-win-color: #FFF;
  --selection-win-extra: #25ac61;
  --selection-lost-bg: #f04149;
  --selection-lost-color: #FFF;
  --selection-lost-extra:  #f04149;
  --selection-draw-bg: #f3f2f7;
  --selection-draw-color: #4a4a4a;
  --selection-default-extra: #4a4a4a;
  --selection-border-color: rgba(0, 0, 0, 0.03);
  // Search
  --search: #f3f2f7;
  --search-text: #333333;
  // Filter
  --filter: #fafafa;
  --filter-expanded: #f3f2f7;
  --filter-color: #000;
  // Checkbox
  --checkbox-bg: #fafafa;
  --checkbox-color: #000;
  // Select
  --select-bg: #FFF;
  --select-label: #525661;
  --select-color: #000;
  // Input
  --input-bg: #FFF;
  --input-color: #000;
  --input-addon-bg: #f3f2f7;
  --input-addon-color: #000;
  // Progress bar
  --progress-history: #ebebeb;
  --progress-history-active: #04c559;
  --progress-history-started: #b6b6b6;
  --progress-join: #f0f0f0;
  --progress-join-portion: #c7e0d7;
  --progress-join-funded: #015d3c;
  // Offer slider
  --slider-inactive: #e8e8e8;
  --slider-connect: rgba(4,197,89, 0.2); // Unselected bg
  --svg-spinner: #000000;

  // Menu
  --navbar: #FFFFFF;
  // A. Vertical menu
  --v-menu-bg: rgba(0, 0, 0, 0%);
  --v-menu-border: rgba(0, 0, 0, 0%);
  --v-menu-color: #000000;
  --v-menu-active-bg: #F5F5F5;
  --v-menu-active-border: rgba(37,172,97,1);
  --v-menu-active-color: rgba(37,172,97,1);
  --v-menu-hover: rgba(0, 0, 0, 0%); // transparent
  --v-menu-hover-text: rgba(37,172,97,.24);
  // B. Horizontal menu
  --h-menu-bg: rgba(0, 0, 0, 0%);
  --h-menu-border: rgba(0, 0, 0, 0%);
  --h-menu-color: #000000;
  --h-menu-active-bg: rgba(0, 0, 0, 0%);
  --h-menu-active-border: rgba(37,172,97,1);
  --h-menu-active-color: rgba(37,172,97,1);

  // Submenu aka tab-menu
  --submenu-color: #333333;
  --submenu-item-bg: #FFF;
  --submenu-item-color: #808080;
  --submenu-item-active-bg: #F5F5F5;
  --submenu-item-active-color: #333333;

  --submenu-reverse-bg: #F5F5F5;
  --submenu-item-reverse-bg: #F5F5F5;
  --submenu-item-reverse-color: #808080;
  --submenu-item-reverse-active-bg: #FFF;
  --submenu-item-reverse-active-color: #333333;

  --scrollbar-bg: #FFF;
  --scrollbar-drag-bg: #333333;

  --headings-color: #333;
}
