.content.leg-indicator {
  @apply flex justify-between items-center py-custom-12 px-custom-16;

  .left-course-info {

  }

  .coupon-sort {
    @apply text-11;

    select.select-dropdown {
      @apply text-11 text-link-color font-semibold;
    }
  }
}


.content.racing-coupon {

  .racing-competitor {
    @apply flex justify-between items-center;
    @apply border-b border-global-border py-custom-12 px-custom-16;

    &.withdrawn {
      opacity: 0.5;
      @apply bg-transparent;
    }
  }

  .racing-competitor-stats {
    @apply flex justify-between items-center p-custom-16;
    @apply border-b border-global-border;
    @apply bg-highlight-bg text-highlight-color;
    min-height: 50px;

    .racing-stat-label {
      @apply font-semibold mr-custom-4;
    }
  }

  .left-content-competitor {
    @apply flex items-center;

    .competitor-numbers {
      @apply flex flex-col items-start mr-custom-16;

      .card-number {
        @apply flex justify-center items-center rounded-full;
        @apply bg-highlight-bg text-highlight-color text-13 font-semibold;
        width: 24px;
        height: 24px;
        &.ghost {
          @apply bg-transparent;
        }
      }

      .racing-stats-chevron-down {
        @apply flex items-center justify-center cursor-pointer;
        width: 21px;
        height: 21px;
      }
    }

    .race-silk {
      height: 26px;
      z-index: 1;
      @apply mr-custom-16;

      &.dog {
        @apply mr-custom-24 rounded-container;
      }
    }

    .fav {
      height: 26px;
      z-index: 1;
      width: 26px;
      @apply flex items-center justify-center mr-custom-16;
      &.dog {
        @apply mr-custom-24;
      }
      &.horses {
        width: 35px;
      }
    }

    .silk-odds-container {
      @apply flex items-center;

      .racing-odds {
        width: 41px;
        @apply mr-custom-12 text-link-color font-semibold;
        &.dogs {
          width: 50px;
        }
      }
    }

    .competitor-info {
      .horse-name {
        @apply mb-custom-2 text-16 font-semibold;
      }

      .jockey-name {
        @apply text-13 text-reduced-color;
      }
    }
  }

  .coupon-btn.racing {
    min-width: 80px;
    &.withdrawn {
      opacity: 0.5;
      pointer-events: none;
      @apply bg-transparent;
    }
    &.disabled {
      &.active {
        @apply bg-selection-active-bg text-selection-active-color;
      }
      opacity: 0.3;
    }
    &.abandoned {
      opacity: 0.3;
      @apply pointer-events-none;
    }

    &.fecta {
      min-width: 40px;
    }
  }
}

// breakpoint added only for small screens

@media (max-width: 420px) {
  .content.racing-coupon {

    .racing-competitor {
        padding-left: 11px !important;
        padding-right: 11px !important;
        position: relative !important;
    }

    .racing-competitor-stats {
      .racing-stat-label {
        display: block;
      }
    }

    .left-content-competitor {

        .competitor-numbers {
            margin-right: 11px !important;
        }
        .race-silk {
            margin-right: 9px !important;
        }

        .fav {
          &.horses {
            position: relative;
            width: 27px;
            i {
              position: absolute;
              left: 7px;
            }
          }
        }

        .silk-odds-container {
            .racing-odds {
              margin-right: -15px;
            }
        }

        .competitor-info {
            width: 160px !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;

            .horse-name {
              font-size: 14px !important;
              overflow-wrap: anywhere;
              line-height: 14px;
              padding: 0 3px 0 7px;
              text-align: center;
              @apply mb-custom-2 text-16 font-semibold;
            }
        }
    }
  }
}
