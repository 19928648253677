.direct-join-comments {

  &.no-margin {
    @apply mb-0;
  }

  /* LOADER */

  .loader {
    @apply flex flex-row justify-center items-center w-full py-custom-12;
    .svg-spinner {
      @apply animate-spin mr-custom-12 h-5 w-5 text-svg-spinner;
    }
  }

  /** ADD NEW COMMENT **/

  .comment-bar {
    @apply flex p-custom-16 flex-row items-center rounded-tl-container rounded-tr-container;
    @apply bg-container-bg text-container-color border-b;
    @apply border-transparent dark:border-container-reduced-color;
    .author-avatar {
      @apply mr-custom-10 inline-block align-middle border-0 max-w-full rounded-full;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      &.ghost {
        @apply opacity-30 bg-help-title-bg border border-global-border-reduced pointer-events-none;
      }
    }
    .comment-input {
      @apply inline-block w-full rounded-nav_link bg-container-bg border border-global-border-reduced shadow-none focus:border-global-border-reduced focus:ring-0;
      @apply p-custom-8 text-14;
      min-height: 40px;
      resize: none;
      &.ghost {
        @apply opacity-30 pointer-events-none border border-global-border-reduced;
      }
      @media (max-width: 767px){
        @apply text-12;
      }
    }

    .action-container {
      @apply flex flex-row justify-between items-center ml-custom-8;
      &.ghost {
        @apply opacity-30 pointer-events-none;
      }
      .action-btn {
        //all: unset; // we reset the element
        @apply p-custom-8 m-custom-4 rounded-nav_link shadow-btn-shadow bg-submenu-item-reverse-active-bg;
        &.right-btn {
          @apply mr-0 text-primary-color;
        }
      }
    }
  }

  /** COMMENT ITEM **/
  .comment {
    @apply relative flex flex-row items-start;
    @apply px-custom-16 py-custom-10 border-b border-l-3 border-r-0 border-t-0 border-transparent;
    transition: background 1s;

    .vertical-divider {
      @apply absolute bg-global-border-reduced;
      top: 46px;
      left: 30px;
      width: 3px;
      height: calc(100% - 46px);
    }

    &.posted-bycaptain {
      @apply bg-list-item-title-bg border-t border-r-0 border-l-3 border-b border-global-border-reduced;
      border-left-color: transparent;
    }

    .manager-avatar {
      @apply relative object-cover rounded-full;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
    }
    .comment-content {
      @apply relative flex flex-col items-start ml-custom-16;
      width: calc(100% - 48px);
    }
    .comment-bubble-text {
      @apply flex flex-row justify-between w-full;
    }
    .comment-body {
      @apply w-full;
      overflow-wrap: anywhere;
      word-break: break-word;
      @media (max-width: 767px){
        @apply text-11;
      }
    }
    .manager-name {
      @apply font-semibold mx-custom-4 ml-0 text-text-color;
      @media (max-width: 767px){
        @apply text-11;
      }
      &.admin {
        @apply text-primary-color;
      }
    }
    .manager-is-captain {
      @apply font-semibold text-reduced-color mr-custom-4;
      @media (max-width: 767px){
        @apply text-11;
      }
    }
    .date-time {
      @apply text-12 leading-32 text-reduced-color;
      @media (max-width: 767px){
        @apply text-11;
      }
    }
    .quick-action-container {
      @apply flex flex-row justify-end items-center w-full h-full text-11;
      @apply mt-custom-8;
    }
    .delete-action, .edit-action {
      @apply flex flex-row justify-center items-center h-full text-11;
      .icon {
        @apply p-custom-4 rounded-full flex flex-row justify-center items-center;
        @apply rounded-nav_link;
        @apply font-semibold;
        width: 24px;
        height: 24px;
        .fa-trash, .fa-edit {
          @apply relative;
        }
        .fa-edit {
          left: 1px;
        }
      }
    }

    .edit-action {
      @apply mr-custom-8;
    }

    .delete-action .icon {
      @apply bg-app-bg text-primary-color border border-container-reduced-color;
    }

    .edit-action .icon {
      @apply bg-app-bg text-container-color border border-container-reduced-color;
    }


    .comment-content.editing {
      // we have them here in case something needs to be specific for editing
      .comment-input {
        @apply inline-block w-full rounded-nav_link bg-container-bg border border-global-border-reduced shadow-none focus:border-global-border-reduced focus:ring-0;
        @apply p-custom-8 text-14;
        min-height: 40px;
        resize: none;
        &.ghost {
          @apply opacity-30 pointer-events-none border border-global-border-reduced;
        }
      }

      .action-container {
        @apply flex flex-row justify-between items-center ml-custom-8;
        &.ghost {
          @apply opacity-30 pointer-events-none;
        }
        .action-btn {
          //all: unset; // we reset the element
          @apply p-custom-8 m-custom-4 rounded-nav_link shadow-btn-shadow bg-submenu-item-reverse-active-bg;
          &.right-btn {
            @apply mr-0 text-primary-color;
          }
        }
      }
    }
  }
}
