.pool-type-group {
  @apply w-full bg-list-item-title-bg text-list-item-title-color;
}

.pool-type-row {
  @apply flex p-0 pt-custom-18 pb-custom-18 pl-custom-8 md:pl-custom-2 items-center rounded-t-container;
  @apply border-b border-container-reduced-color;
  @apply bg-list-item-title-bg text-list-item-title-color;
  @media screen and (max-width: 479px) {
    @apply pb-custom-14 pt-custom-14;
  }


  .sport-icon {
    //@apply flex items-center justify-center bg-highlight-bg rounded-full w-p30 h-p30 p-custom-7 mr-custom-12;
    @apply flex items-center justify-center bg-primary-color rounded-none w-p30 h-p30 p-custom-7 mr-custom-12;
    min-width: 30px;
    min-height: 30px;
    span {
      // To be removed when icons will be changed to SVG
      font-size: 24px;
      line-height: 16px;
    }
  }

  .text-block {
    @apply text-20 font-normal;
    //@apply font-accent;
  }
}

.pool_list_item {
  &.last {
    @apply rounded-b-container;
  }
}

.pool_list_item__item {
  @apply flex flex-row py-custom-8 px-custom-8 md:px-custom-2 justify-between items-center min-h-pool_list_item;
  @apply border-b border-global-border;
  @apply bg-list-item-bg text-reduced-color;
  .last & {
    @apply rounded-b-container border-b-0;
  }

  transition: all 200ms ease;

  &:hover {
    filter: brightness(95%);
    @apply cursor-pointer;
  }

  .left-content {
    @apply flex flex-row justify-between align-middle;
    .pick-and-date {
      @apply flex items-center;
      .pool-item-left-content {
        .pick-x {
          width: 110px;
          @apply leading-19 text-list-item-color font-default;
          &.racing {
            @apply text-13 leading-16 font-semibold;
          }

          &.boost {
            @apply text-boosted-color;
          }

          &.sub-code {
            @apply pr-custom-8 leading-17 text-12 text-reduced-color;
            .competitors {
              flex-wrap: wrap;
            }
          }

          @media screen and (max-width: 479px) {
            width: 119px;
            min-width: 40%;
          }
        }

        .flag {
          height: 12px;
        }
      }

      .date {
        width: 81px;
        @apply text-reduced-color text-13 font-normal;
        @media screen and (max-width: 479px) {
          width: 200px;
        }

        .timer {
          //@apply font-bold;
          > .active {
            @apply text-timer-countdown;
          }
          &.started, .started {
            @apply text-timer-zero;
          }
          .last-hour {
            @apply text-timer-countdown;
          }
        }
      }

      @media screen and (max-width: 479px) {
        @apply w-full;
      }
    }

    @media screen and (max-width: 479px) {
      @apply w-1/2;
      flex: 1;
    }
  }

  .right-content {
    @apply flex flex-row items-center;
    .jackpot {
      @apply flex pr-0 text-right justify-end;
      @apply text-list-item-color text-16 font-semibold;
      min-width: 113px;
      @media screen and (max-width: 991px) {
        min-width: 102px;
        @apply mr-custom-16;
      }
      @media screen and (max-width: 767px) {
        @apply mr-0;
        min-width: 94px;
      }
      @media screen and (max-width: 479px) {
        @apply mr-0 text-16;
      }
    }

    .syndicates-available {
      @apply text-right font-medium text-link-color;
      min-width: 161px;
      @media (max-width: 991px) {
        min-width: 118px;
        @apply text-12;
        white-space: break-spaces;
      }
      @media (max-width: 767px) {
        width: 163px;
        min-width: 0;
        @apply text-13;
      }
      @media (max-width: 479px) {
        display: none;
      }
    }

    .pool-icon-chevron {
      @apply flex ml-custom-4 justify-center items-center text-reduced-color;
      width: 20px;
      height: 20px;
    }

    @media screen and (max-width: 991px) {
      @apply w-full justify-end;
    }
    @media screen and (max-width: 767px) {
      @apply justify-end;
    }
    @media screen and (max-width: 479px) {
      width: auto;
      @apply items-center;
    }
  }
}
